
<template>
   <BaseDialog :show=true :hideCloseBtn=true style="padding: 10px 20px; width: 350px ;height: 100px ;background-color: var(--overwatch-light-button-primary)">
    <div class ="d-flex" id="rocToast" style="gap: 10px; padding-top: 15px">
         <div class="circle">
            <RocIcon icon="check" size="md" color="primary"/>
        </div>
      <p style="color: var(--overwatch-dark-neutral-100);">{{ message }}</p>
    </div>
    </BaseDialog>
</template>

<script>
import RocIcon from '@/components/ui/RocIcon';
import BaseDialog from "@/components/ui/BaseDialog.vue";

export default {
    name: 'RocToast',
    emits: ['autoClose'],
    components: {
    RocIcon,
    BaseDialog,
  },
   props: {
     message: {
      type: String,
      required: true,
      default: '',
    },
    timeout:{
      type: Number,
      default: 800,
    }
   },
   setup(props, context) {

    setTimeout(function () {
        context.emit('autoClose');
    }, props.timeout);

    return {

    };
   }

}
</script>

<style scoped lang="scss">
.circle {
    background: var(--overwatch-dark-neutral-100);
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: center;
}
</style>