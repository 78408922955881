<template>
  <div class="watchlist-card" :title="name">
    <div>
      <audio preload ref="AlarmAudio">
        <source src="@/assets/audio/alert.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div class="d-flex justify-content-between watchlist-header disable-select">
      <router-link :to="editUrl">
        <div class="d-flex flex-row">
          <div :class="[watchlistActive ? 'active' : 'inactive']" >{{ name }}</div>
        </div>
      </router-link>
      <RocCheckbox v-if="selectMode"
        v-model="isChecked"
        @click.stop
      />
      <div v-else>
        <RocPopper hover arrow placement="top" popper-type="tooltip" v-if="restricted" v-model="showTooltip">
          <RocIcon icon="lock" size="sm" />
          <template #content>
            Only users with permissions can edit this watchlist. Contact your administrator to request access
          </template>
        </RocPopper>
        <MDBDropdown v-else v-model='dropdownOptions' align='end'>
          <MDBDropdownToggle
              style='color:black;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;'
              tag='a'
              @click="dropdownOptions = !dropdownOptions">
              <RocIcon color="black" size="sm" icon="kebab" :alt="`${name}-dropDown`"/>
          </MDBDropdownToggle>
          <RocDropdownMenu
            class="view-dropdown-menu"
            aria-labelledby='dropdownMenuButton'
            style="box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);"
          >
            <div v-if="!isEvent">
              <MDBDropdownItem href="" @click.prevent>
                <div @click="toggleEnabled">
                  <RocIcon v-if="stateText == 'Disable'" icon="nomatch" size="sm" style="margin-right: var(--spacing-xs);"/>
                  <RocIcon v-else icon="circleCheck" size="sm" style="margin-right: var(--spacing-xs);"/>
                  {{ stateText }}
                </div>
              </MDBDropdownItem>
             <div class="divider"></div>
            </div>
            <MDBDropdownItem tag="button" @click="showMatchThreshold = !showMatchThreshold" class="drop-menu-item" >
              <div>
                <RocIcon icon="match" size="sm" style="margin-right: var(--spacing-xs);"/>
                Match Threshold
              </div>
              <RocIcon icon="downArrow" size="xs" :rotate270="!showMatchThreshold" style="margin-left: 10px;"/>
              <div v-if="showMatchThreshold" style="width:100%;">
                <RocRange v-model="matchThresholdSlider" @mouseup="setMatchThreshold"/>
              </div>
            </MDBDropdownItem>
            <div class="divider"></div>
            <MDBDropdownItem tag="button">
              <div class='d-flex align-items-center' style="width: 100%; gap: var(--spacing-l)">
                <div style="margin-right: 6px;">
                  <RocIcon icon="volumeUp" size="sm" @click="playAlarmSound" style="margin-right: var(--spacing-xs); cursor: pointer"/>
                  Audible Alarm
                </div>
                <RocSwitch :isActive="audibleAlarmState" @switch-toggled="audibleAlarmState = $event"
                style="margin-left: auto"/>
              </div>
            </MDBDropdownItem>
            <div class="divider"></div>
            <MDBDropdownItem tag="button" class="drop-menu-item" @click.stop="showColorPicker = !showColorPicker">
              <div>
                <RocIcon icon="colorPicker" size="sm" style="margin-right: var(--spacing-xs);"/>
                Color
              </div>
              <RocIcon icon="downArrow" size="xs" :rotate270="!showColorPicker"/>
              <div v-if="showColorPicker">
                <WatchlistColorPicker :selectedColor="watchlistColor" @color-picked="setWatchlistColor" :showPicker="true"/>
              </div>
            </MDBDropdownItem>
            <div v-if="!isEvent && isAdmin">
              <div class="divider"></div>
              <MDBDropdownItem tag="button" class="drop-menu-item" @click.stop="showUserGroups = !showUserGroups">
                <div>
                  <RocIcon icon="group" size="sm" style="margin-right: var(--spacing-xs);"/>
                  User Groups
                </div>
                <RocIcon v-if="!showUserGroups" :rotate270="true" icon="downArrow" size="xs"/>
                <RocIcon v-else icon="downArrow" size="xs" @click.stop="showUserGroups = !showUserGroups"/>
                <div v-if="showUserGroups">
                  <filterMultiSelect @click.stop style="max-width: 500px; width: 175px; margin-top: var(--spacing-m);"
                    mode="tags" :close-on-select="false"
                    placeholder-text="Select User Groups" :available-items="allUserGroups"
                    :currently-selected="selectedUserGroupIds" @selection-changed="updateSelectedUserGroups" :enableClear="false"
                />
                </div>
              </MDBDropdownItem>
            </div>
            <div v-if="isAdmin">
              <div class="divider"></div>
              <MDBDropdownItem href="" @click.prevent style="display: flex; flex-wrap: wrap;justify-content: space-between; ">
                <div @click="showEditPermissions = !showEditPermissions; dropdownOptions = !dropdownOptions;">
                  <RocIcon icon="lock" size="sm" style="margin-right: var(--spacing-xs);"/>
                  Edit Permissions
                </div>
                <RocIcon icon="outsideLink" size="sm"/>
              </MDBDropdownItem>
            </div>
            <div v-if="isAdmin">
              <div class="divider"></div>
              <MDBDropdownItem href="" @click.prevent style="display: flex; flex-wrap: wrap;justify-content: space-between; ">
                <div @click="showAdvancedSettings = !showAdvancedSettings; dropdownOptions = !dropdownOptions">
                  <RocIcon icon="settings" size="sm" style="margin-right: var(--spacing-xs);"/>
                  Advanced Settings
                </div>
                <RocIcon icon="outsideLink" size="sm"/>
              </MDBDropdownItem>
            </div>
            <div v-if="!isEvent">
              <div class="divider"></div>
              <MDBDropdownItem divider />
              <MDBDropdownItem tag="button" class="drop-menu-item">
                <div @click="download">
                  <RocIcon icon="download" size="sm" />
                  Download
                </div>
              </MDBDropdownItem>
            </div>
            <div v-if="!isEvent && isAdmin">
              <div class="divider"></div>
              <MDBDropdownItem divider />
              <MDBDropdownItem href="" @click.prevent>
                <div @click="isShowingDelete = true; dropdownOptions = false;">
                  <RocIcon icon="trash" size="sm" color="red" style="margin-right: var(--spacing-xs);"/>
                  Delete
                </div>
              </MDBDropdownItem>
            </div>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; align-items: flex-start;" :id="`${name}-${size}`">
      <div v-if="wlType === 'face'" class="size-header">
        <span>
          <div class="pill">
            <span class="overwatch-title-xsmall d-flex" style="color:#000000; gap: var(--spacing-base)">
              <RocIcon icon="faceSearch" size="sm" :color="watchlistIconColor" style="margin-right:4px"/>
              <span>{{size}} </span>
            </span>
          </div>
        </span>
      </div>
      <div v-else-if="wlType === 'tattoo'" class="size-header">
        <div class="pill">
          <span class="overwatch-title-xsmall d-flex" style="color:#000000; gap: var(--spacing-base)">
            <RocIcon icon="gallery" size="sm" :color="watchlistIconColor" style="margin-right:4px" />
            <span style="color:#000000">{{size}} </span>
          </span>
        </div>
      </div>
      <div v-else-if="wlType === 'text'" class="size-header">
        <div class="pill">
          <span class="overwatch-title-xsmall d-flex" style="color:#000000; gap: var(--spacing-base)">
            <RocIcon icon="vehicle" size="sm" :color="watchlistIconColor" style="margin-right:4px" />
            <span> {{size}}</span>
          </span>
        </div>
      </div>
      <div v-if="watchlist.syncInfo && watchlist.syncInfo.isImported" class="size-header" style="margin-left: 15px;" title="Watchlist Originated from a Remote Source">
        <RocIcon icon="organization" size="sm" color="black"/>
      </div>
    </div>
    <div v-if='isLoading' style="position: absolute; margin-left: 20rem; margin-top: -20px;">
      <roc-spinner/>
    </div>
    <div v-if="wlType === 'face' || wlType === 'tattoo'" class="container" :style="parentStyle">
      <div v-for='member in memberSummary' :key="member.id" style="object-fit: contain; height: 111px;">
        <auth-img :src='member.url' style="max-height: 100%;"/>
      </div>
    </div>
    <div v-else-if="wlType === 'text'" class="text-container" :style="parentStyle">
      <div v-for='member in memberSummary' :key="member.id">
        <div style="position: relative; border: 0px solid var(--overwatch-error); margin-right: var(--spacing-base);">
            <img src="@/assets/img/blank-plate-gray.png">
            <div class="plate-number center">{{member.plateNumber}}</div>
        </div>
      </div>
    </div>
    <base-dialog v-if="showAdvancedSettings" :show="true" title="Advanced Watchlist Settings" @close="showAdvancedSettings = false;" :style="advancedSettingsStyle">
      <WatchlistSettings @save="$emit('save')" @close="showAdvancedSettings=false;" :watchlist="watchlist"></WatchlistSettings>
    </base-dialog>
    <base-dialog v-if="showEditPermissions" style="padding-top: var(--spacing-xl);" :hide-close-btn="true" :show="true" @close="showEditPermissions = false; editPermissionsDialog.clear();" >
      <EditWatchlistPermissions
        ref="editPermissionsDialog"
        :isEditing="true"
        v-model:userAccessList="userAccessList"
        v-model:restricted="restrictedWatchlist"
        :show-restricted="showRestricted"
        @close="() => { setEditPermissions(); showEditPermissions = false; editPermissionsDialog.clear(); }"
      />
    </base-dialog>
    <base-dialog v-if="isShowingDelete" :show="true" @close="isShowingDelete=false;" :style="deleteConfirmationStyle">
      <DeleteConfirmation
        @close="isShowingDelete=false"
        @disable="disableWatchlist"
        @delete="deleteWatchlist"
        :message="'Yes'"
        :cancelMessage="'No'"
      >
      <div class="d-flex">
        <RocIcon icon="trash" color="red" style="margin-right: var(--spacing-m);"/>
        <div>
          Are you sure you want to delete
          <div>
             watchlist <span style="text-decoration: underline;">{{name}}</span>?
          </div>
        </div>
      </div>
      </DeleteConfirmation>
    </base-dialog>
  </div>
</template>

<script>
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
} from "mdb-vue-ui-kit";
import { computed, ref, watch, onMounted} from "vue";
import { useStore } from "vuex";
import AuthImg from "@/components/ui/AuthImg";
import BaseDialog from "@/components/ui/BaseDialog";
import WatchlistSettings from "@/components/watchlists/WatchlistSettings";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import WatchlistColorPicker from "@/components/watchlists/WatchlistColorPicker";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocCheckbox from "@/components/ui/RocCheckbox"
import RocIcon from "@/components/ui/RocIcon"
import RocSwitch from "@/components/ui/RocSwitch"
import RocRange from "@/components/ui/RocRange"
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocButton from '@/components/ui/RocButton.vue';
import RocPopper from '@/components/ui/RocPopper.vue';
import EditWatchlistPermissions from '@/components/watchlists/EditWatchlistPermissions.vue';

export default {
  name: "WatchlistView",
  emits: ["save", "check"],
  components: {
    EditWatchlistPermissions,
    RocPopper,
    RocButton,
    AuthImg,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    BaseDialog,
    WatchlistSettings,
    DeleteConfirmation,
    WatchlistColorPicker,
    filterMultiSelect,
    RocCheckbox,
    RocIcon,
    RocSwitch,
    RocRange,
    RocDropdownMenu,
  },
  props: {
    id: String,
    name: String,
    enabled: Boolean,
    restricted: Boolean,
    size: Number,
    memberSummary: Array,
    matchThreshold: Number,
    audibleAlarm: Boolean,
    wlType: String,
    watchlist: Object,
    color: String,
    selectMode: Boolean,
    isSelected: Number
  },
  setup(props, context)
  {
    const store = useStore();
    const dropdownOptions = ref(false);
    const watchlistActive = ref(props.enabled);
    const parentStyle = ref(null);
    const isLoading = ref(false);
    const matchThresholdSlider = ref(props.matchThreshold * 100);
    const audibleAlarmState = ref(props.audibleAlarm);
    const AlarmAudio = ref(null);
    const colorPickerActive = ref(false);
    const editUrl = ref({name: 'WatchlistEditor', params: {id : props.id, watchlistName: props.name, wltype: props.wlType, enabled: props.enabled}, query: {wlname: props.name}} );
    const watchlistColor = ref('#fcfcfc');
    const isShowingDelete = ref(false);
    const showAdvancedSettings = ref(false);
    const showEditPermissions = ref(false);
    const showColorPicker = ref(false);
    const showMatchThreshold = ref(false);
    const showUserGroups = ref(false);
    const selectedUserGroupIds = ref(props.watchlist.userGroups);
    const showTooltip = ref(false);

    const userAccessList = ref(props?.watchlist?.editPermissions ?? []);
    const restrictedWatchlist = ref(props?.watchlist?.restricted ?? false);
    const showRestricted = ref(props?.watchlist?.showRestricted ?? false);
    const editPermissionsDialog = ref();

    function download() {
      location.href = `/rest/v1/watchlist/${props.id}/gallery`
    }
    const isAdmin = computed(function() {
      return store.getters['auth/isAdmin'];
    });

    setStyle();
    const windowWidth = ref(window.innerWidth);

    onMounted(async () => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    watch(audibleAlarmState, () => {
      setAudibleAlarm(audibleAlarmState.value)
    });

    watch(dropdownOptions, () => {
      if(dropdownOptions.value === false && selectedUserGroupIds.value.toString() !== props.watchlist.userGroups.toString())
        updateUserGroups()
    });

    async function setEditPermissions(){
      await updateWatchlist({id: props.id, update: {editPermissions: [...userAccessList.value], restricted: restrictedWatchlist.value, }});
      context.emit('save');
    }

    async function setMatchThreshold() {
      await updateWatchlist({id: props.id, update: {matchThreshold: (matchThresholdSlider.value / 100)}});
      context.emit('save');
    }

    async function setAudibleAlarm(state) {
      await updateWatchlist({id: props.id, update: {audibleAlarmEnabled: state}});
      context.emit('save');
    }

    const stateText = computed(function () {
      if(watchlistActive.value) {
        return "Disable"
      }
      else
      {
        return "Enable";
      }
    });

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

     const watchlistIconColor = computed(() => {
          if(darkMode.value) return 'white';
          return 'black';
    });

    function setStyle() {
      if(watchlistActive.value) {
        parentStyle.value = "";
      }
      else {
        parentStyle.value = "opacity: .4";
      }
      // init watchlist style to include assigned color
      watchlistColor.value = props.color;
    }

    async function updateWatchlist(update) {
      isLoading.value = true;
      try {
        await store.dispatch("watchlists/updateWatchlist", update);
      }
      catch(error) {
        error.value = error.message || 'Something went wrong!';
      }
      setStyle();
      isLoading.value = false;
    }

    async function setWatchlistColor(color) {
      watchlistColor.value = color;
      showColorPicker.value = false;
      let update = {id: props.id, update: {color: watchlistColor.value}};
      try {
        //I need to update the store here.
        await store.dispatch("watchlists/updateWatchlist", update);

      }
      catch(error) {
        error.value = error.message || "Something went wrong!";
      }
      colorPickerActive.value = false;
    }

    async function toggleEnabled() {
      watchlistActive.value = !watchlistActive.value;
      await updateWatchlist({id: props.id, update: {enabled: watchlistActive.value}});
      dropdownOptions.value = !dropdownOptions.value;
    }

    async function disableWatchlist() {
      watchlistActive.value = false;
      await updateWatchlist({id: props.id, update: {enabled: watchlistActive.value}});
      dropdownOptions.value = false;
    }

    async function deleteWatchlist() {
      dropdownOptions.value = false;
      isLoading.value = true;
      parentStyle.value = "opacity: .4";
      try {
        await store.dispatch("watchlists/deleteWatchlist", {id: props.id});
      }
      catch(error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    function playAlarmSound() {
      try {
        AlarmAudio.value.play();
      } catch (err) {
        console.error(err);
      }
    }

    const advancedSettingsStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: '100%',
          height: '100%',
        }
      } else {
        return {
          width: '1000px',
          height: '750px',

          left: '50%',
          'z-index': '1000'
        }
      }
    });

    const deleteConfirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%'
        }
      }
    });

    const isEvent = computed(() => {
      return props.watchlist.hasOwnProperty('eventValidity');
    });

    const allUserGroups = computed(() => {
      return store.getters['settings/userGroups'];
    });
    initUserGroups();
    function initUserGroups() {
      try {
        // iterate available usergroups to check if selected or readonly
        for (let i=0; i < allUserGroups.value.length; i++) {
          allUserGroups.value[i].value = allUserGroups.value[i]._id;
          // if usergroup is system and user not in system group, mark it disabled
          if (allUserGroups.value[i].isSysAdmin && !userGroupContainsCurrentUser(allUserGroups.value[i])) {
            allUserGroups.value[i].disabled = true;
          } else {
            allUserGroups.value[i].disabled = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // check if currently logged in user is a part of provided usergroup
    function userGroupContainsCurrentUser(userGroup) {
      return userGroup.users.find((user) => {
        if (user.email === store.getters['auth/email']) {
          return true;
        }
      });
    }

    function updateSelectedUserGroups(selectedUserGroups) {
      selectedUserGroupIds.value = selectedUserGroups.value;
    }

    async function updateUserGroups() {
      let update = {id: props.id, update: {userGroups: selectedUserGroupIds.value}};
      try {
        await store.dispatch("watchlists/updateWatchlist", update);
      } catch(error) {
        error.value = error.message || "Something went wrong!";
      }
      showUserGroups.value = false;
      dropdownOptions.value = false;
    }

    const isChecked = ref(false);
    watch(isChecked, nv => {
      context.emit("check", nv);
    });

    watch(() => props.isSelected, nv => {
      if (nv === 1 || nv === -1) {
        isChecked.value = nv === 1;
      }
    });

    return {
      editPermissionsDialog,
      setEditPermissions,
      userAccessList,
      restrictedWatchlist,
      showRestricted,
      showTooltip,
      download,
      dropdownOptions,
      stateText,
      toggleEnabled,
      parentStyle,
      watchlistActive,
      disableWatchlist,
      deleteWatchlist,
      watchlistIconColor,
      isLoading,
      matchThresholdSlider,
      setMatchThreshold,
      editUrl,
      setAudibleAlarm,
      audibleAlarmState,
      AlarmAudio,
      playAlarmSound,
      showAdvancedSettings,
      showEditPermissions,
      isAdmin,
      advancedSettingsStyle,
      watchlistColor,
      colorPickerActive,
      setWatchlistColor,
      isShowingDelete,
      deleteConfirmationStyle,
      isEvent,
      showColorPicker,
      showMatchThreshold,
      showUserGroups,
      allUserGroups,
      selectedUserGroupIds,
      updateSelectedUserGroups,
      updateUserGroups,
      isChecked,
    };
  }
};
</script>

<style scoped lang="scss">
.watchlist-card {
  box-sizing: border-box;
  height: 196px;
  width: 470px;
  border-radius: 5px;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: var(--spacing-xs);

  display:flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.color-watchlist-card {
  box-sizing: border-box;
  height: 197px;
  width: 670px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: var(--spacing-base);
  background-color: var(--overwatch-secondary);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.watchlist-header {
  @include overwatch-body-med;
  margin-top: var(--spacing-m);
  margin-left: 21px;
  margin-right: 21px;
}

.size-header {
  @include overwatch-body-small;
  line-height: var(--spacing-s);
  margin-left: 21px;
  margin-top: 0px;
  display: inline-block;
}

.container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow: hidden;
  margin-top: 6px;
  max-width: 650px;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  margin-top: var(--spacing-base);
  max-width: 650px;
  margin-left: 10px;
  margin-right: 10px;
}

img {
  margin: 0 2px;
  border-radius: var(--spacing-base);
}

.bottomBorder {
  border-bottom: 4px solid #1a816f;
}

.pill{
    display: flex;
    width: fit-content;
    margin-right: 0px;
    align-items: center;
    line-height: 48px;
    border-radius: 48px;
    padding: 10px;
    background-color: v-bind(watchlistColor);
    color:gray;
    height: 20px;
}

.dropdown-toggle:after {
  display: none;
}
.header-options-dd {
 @include overwatch-body-med;
}

.hidden-drop-down {
  display:flex;
  flex-wrap: wrap;

}

.disabled {
  width: 83px;
  color: var(--overwatch-error);
  @include overwatch-body-med;
  letter-spacing: 1px;
  margin-left: auto;
  margin-right: 1rem;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.plate-number {
  font-family: Sans-serif;
  font-size: 20px;
  color: var(--overwatch-light-neutral-100)
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.active {
  color: var(--overwatch-light-button-primary);
  margin-bottom:var(--spacing-base);
}

.inactive{
  color: var(--overwatch-dark-neutral-500);
  margin-bottom:var(--spacing-base);
}

:deep(.drop-menu-item) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.divider {
  height: 1px;
  background-color: var(--overwatch-light-accent);
}

// Sean (03-31-24): Hard coding colors for these interactive menu elements.
// We should revamp the menu in the future
.view-dropdown-menu :deep(button) {
  color: var(--overwatch-neutral-100);
}

.view-dropdown-menu :deep(button):hover {
  background-color: var(--overwatch-button-primary-20);
}

.view-dropdown-menu :deep(button):focus {
  background-color: var(--overwatch-button-primary-20);
}

@media (max-width: 480px) {
  .watchlist-card {
    min-width: 100%;
  }

  .text-container div {
    width: 33.333333%
  }
}
</style>
