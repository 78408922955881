import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    userId: null,
    firstname: '',
    lastname: '',
    email: '',
    token: null,
    refreshToken: null,
    isAuthenticated: false,
    isSSO: false,
    server_id: '',
    server_name: '',
    server_protocol: '',
    server_host: '',
    server_port: '',
    lastRefreshDelay: 0,
    lastRefreshTimeout: 0,
    presetServers: [],
    oneTimeToken: null,
    userConfig: {}, //holds rww settings for login/logout and saved views
    userSettingsChanged: 0, //timestamp value that notifies App.vue to save userConfig
    preAuthConfig: null
  }
}

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
