<script setup>
import { ref, watch } from 'vue';
import RocIcon from "@/components/ui/RocIcon.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";

const props = defineProps({
    columnDefs: {
      type: Array,
      default: [],
      notes: 'Col defs has, hide , editable, label, and key. Editable: false ==> cant edit, hide ==> will be hidden when editing for a text field'
    },
    rows: {
        type: Array,
        default: [],
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    editNotesField: {
      type: Boolean,
      default: false
    },
  });

  const emits = defineEmits(['deleteAll','deleteItem','contentChanged']);
  const isEditing = ref(-1); 
  const editNotes = ref('');

  function contentChanged(e, index) {
    emits('contentChanged', e, index); //get the ID and val off e.
    isEditing.value = -1;
  }

  function deleteAll() {
    emits('deleteAll');
  }

  function deleteItem(index) {
    emits('deleteItem', index);
  }

  function setCurrentlyEditing(index) {
    isEditing.value = index;
  }

  function getExistingNotesVal(obj) {
    editNotes.value = obj['notes'] ?? undefined;
    if(obj['notes']?.trim() != '')
      return obj['notes']
    else return 'Type your notes here';
  }
</script>

<template>
  <div id="table" style="width: 100%">
    <table style="width: 100%">
      <thead>
        <tr>
          <th v-for="(colDef,index) in columnDefs"  :key="index" :id="`${colDef.key}` ">
            {{ colDef.label }}
          </th>
          <th v-if="allowDelete" style="text-align: right;">
            <RocIcon icon="trash" color="red" size="sm" @click="deleteAll" style="margin-right: var(--spacing-m); cursor: pointer;" />
          </th>
        </tr>
      </thead>
        <tbody>
        <tr v-for="(obj,index) in rows" :key="obj.key" class="tableContent" :id="`rocRow-${index}`">
          <td v-for="colDef in columnDefs" :id="`${colDef.key}`" :key="colDef.key" :contenteditable="isEditing == index && colDef.editable" @blur="contentChanged($event, index)">
            <img v-if="colDef.key === 'img'" :src="obj[colDef.key]" style='max-height: 50px; width: auto; '/>
            <slot v-else-if="colDef.key === 'status'"
              :name="'status'"
              :value="obj[colDef.key]"
              :error="obj.errorMsg ?? 'error'"
            >
            </slot>
            <RocTextArea v-else-if="colDef.hide && isEditing == index" rows="1" :placeholder="getExistingNotesVal(obj)" v-model="editNotes" @blur="contentChanged($event, index)"/>
            <span v-else>{{ obj[colDef.key] }} </span>
          </td>
          <td v-if="allowDelete || allowEdit" style="cursor: pointer; text-align: right;">
            <RocIcon v-if="allowEdit && isEditing != index" icon="edit" color="primary" size="sm" @click="setCurrentlyEditing(index)"/>
            <RocIcon v-else-if="allowEdit" icon="check" color="primary" size="sm" @click="setCurrentlyEditing(-1)"/>
            <RocIcon v-if="allowDelete" icon="trash" color="red" size="sm" @click="deleteItem(index)" style="margin-left: 10px; margin-right: var(--spacing-base);"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">

.tableContent {
  width: 100% !important;
  height: 45px;
  margin: 0;
  background: var(--overwatch-secondary);
  margin-right: 0rem !important;
  border: 1px solid var(--overwatch-button-primary);
  border-radius:var(--spacing-base) var(--spacing-base) 0 0;
  border-collapse: collapse;
}

td {
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  background: var(--overwatch-secondary);
}

th {
  @include overwatch-title-xsmall;
  height: 45px;
}

thead {
  text-align: center;
  vertical-align: middle;
  border: 1px solid var(--overwatch-button-primary);
  border-radius:var(--spacing-base) var(--spacing-base) 0 0;
  background: var(--overwatch-light-button-primary-20);
}

#img{
  display: flex;
}

</style>