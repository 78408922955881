<template>
  <div class="encounter" @mouseover="isHovering=true" @mouseleave="isHovering=false"
    :class="{
      isFocused: isFocused,
      associatesViewMode: associatesViewMode
    }"
    @click="setVideoPlaybackEncounter"
  >
    <auth-img class="thumbnail" :src="computedEncounterThumbnail" draggable="false">

    </auth-img>
    <div class="date">{{ formattedDateString }}</div>

    <div class="check" v-show="(isHovering || isChecked || isMenuOpen) && !associatesViewMode && isAdminOrPowerUser" @click.stop>
      <RocCheckbox
        v-model="isChecked"
        white
      />
    </div>
    <MDBDropdown class="menu"
      :class="{
        associatesViewMode: associatesViewMode
      }"
      v-show="(isHovering || isMenuOpen) && isAdminOrPowerUser"
      v-model="isMenuOpen"
    >
      <RocDropdownToggle @click.stop="isMenuOpen = !isMenuOpen">
        <RocIcon
          size="sm"
          icon="kebab"
          rotate90
          style="color: white;"
        />
      </RocDropdownToggle>
      <RocDropdownMenu @click.stop="isMenuOpen = false">
        <RocDropdownSub v-if="!associatesViewMode">
          Move to
          <template #menu>
            <RocDropdownItem @click="moveToNewCluster()">
              New Cluster
            </RocDropdownItem>
            <RocDropdownItem @click="moveToExistingCluster()">
              Existing Cluster
            </RocDropdownItem>
          </template>
        </RocDropdownSub>
        <RocDropdownItem @click="enrollFace">
          Add to Watchlist
        </RocDropdownItem>
        <RocDropdownItem @click="setAsCoverImage()" v-if="!associatesViewMode">
          Set As Cover Image
        </RocDropdownItem>
      </RocDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import RocCheckbox from '@/components/ui/RocCheckbox';
import { MDBDropdown } from 'mdb-vue-ui-kit';
import RocIcon from '@/components/ui/RocIcon';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu.vue';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle.vue';
import RocDropdownItem from '@/components/ui/RocDropdownItem';
import RocDropdownSub from '@/components/ui/RocDropdownSub';

import userAccessHelper from "@/js/userAccessHelper";

const store = useStore();

const props = defineProps({
  encounter: {
    type: Object,
    required: true
  },
  associatesViewMode: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['check', 'move-new', 'move-existing', 'delete', 'set-cover', 'enroll-face']);

const computedEncounterThumbnail = computed(() => {
  const faceTemplateId = props.encounter.facetemplateId;
  return `/rest/v1/image/face/${faceTemplateId}/tn`;
});

// Aug 3, 2023 10:00:10 AM Date format
const formattedDateString = computed(() => {
  const d = moment(new Date(props.encounter.track_info.start_timestamp));
  const formatted = d.format("MMM DD, yyyy HH:mm:ss");
  return formatted;
})

const isChecked = ref(false);

const isMenuOpen = ref(false);

const isHovering = ref(false);

function moveToNewCluster() {
  emit('move-new');
}

function moveToExistingCluster() {
  emit('move-existing');
}

watch(isChecked, nv => {
  emit('check', nv);
});

const isFocused = computed(() => {
  return props.encounter._id === store.getters['clusters/videoPlaybackEncounter']?._id;
})

function setAsCoverImage() {
  emit('set-cover');
}

function setVideoPlaybackEncounter() {
  store.commit('clusters/setVideoPlaybackEncounter', props.encounter);
}

const isAdminOrPowerUser = computed(() => {
  const helper = new userAccessHelper();
  return helper.isAdminOrPowerUser();
});

function enrollFace() {
  emit("enroll-face", {
    templateId: props.encounter.faceTemplateId,
    thumbnail: computedEncounterThumbnail.value,
    personId: props.encounter.personId
  });
}

</script>

<style scoped lang="scss">
.encounter {
  /**
   * Similarly to ClusterCard, set to content-box
   * so that border doesn't shift layout around.
   */
  box-sizing: content-box;

  border-radius: 5px;
  position: relative;

  height: 140px;
  width: 90px;
}

.encounter.associatesViewMode {
  box-sizing: content-box;

  height: 140px;
  width: 90px;

  box-shadow: 0 2px 4px 0 var(--overwatch-overlay);
  background-color: var(--overwatch-secondary);
  padding: var(--spacing-base);
}


.encounter.isFocused {
  border: solid 2px var(--overwatch-button-primary);
}

.thumbnail {
  height: 100px;
  width: 100%;
}

.date {
  @include overwatch-body-small;
}

.controls {
  position: absolute;
  top: $--spacing-base;
  padding: 0 $--spacing-base;
  width: 100%;
  display: flex;
  justify-content: space-between;

  :deep(input) {
    background: none;
  }
}

.check {
  position: absolute;
  top: $--spacing-base;
  left: $--spacing-base;

  :deep(input) {
    background: none;
  }
}

.menu {
  position: absolute;
  top: $--spacing-base;
  right: $--spacing-base;
}
.menu.associatesViewMode {
  right: $--spacing-xs;
}
</style>