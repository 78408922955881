export function formatSimilarityScore(similarity, round=false) {
  const normalizedScore = (similarity <= 1) ? (similarity * 100) : similarity;
  if (normalizedScore >= 100) {
    return normalizedScore;
  } else {
    return normalizedScore.toFixed(round ? 0 : 1);
  }
}

export function getVersionInfo() {
        let versionInfo;
        try {
            versionInfo = require("../versionInfo"); //prod in root
        }

        catch(e) {
            try {
                versionInfo = require("../../../node-server/versionInfo");
                
            }
            catch(e) {
              versionInfo = null;
            }
        }

        try {
            const appVersion = new versionInfo();
            return appVersion;
        }
        catch(e) {
          console.log(e);
          return e;
        }
}