<template>
  <div class="disable-select">
    <MDBNavbar class="navbar" container expand='lg' id="AppNavBar">
      <MDBNavbarBrand href='#'>
        <router-link class='header-options-dd' to='/'>
            <RocLogo style="height: 50px; width: 90px;"/>
        </router-link>
      </MDBNavbarBrand>
      <MDBNavbarNav class="navbar-nav">
        <MDBNavbarItem class="actionItem">
          <router-link :to="{ path: '/missions' }">
            Missions
          </router-link>
        </MDBNavbarItem>
        <MDBNavbarItem v-if="isPageAllowed('/encounters')" class="actionItem mobile">
          <router-link :to="{ path: '/encounters' }">
            Encounters
          </router-link>
        </MDBNavbarItem>
        <MDBNavbarItem v-if="isPageAllowed('/cameras')" class="actionItem">
          <router-link :to="{ path: '/cameras' }" v-if="isLiveActiveMission">
            Cameras
          </router-link>
          <RocPopper arrow hover popperType="tooltip" placement="bottom" v-else>
            <a class="disabled">
              Cameras
            </a>
            <template #content>
              Please select an active Live Mission to access camera settings.
            </template>
          </RocPopper>
        </MDBNavbarItem>
        <MDBNavbarItem class="actionItem mobile">
          <BaseDropdown v-model="isDataSourcesShowing" show>
            <MDBDropdownToggle @click="isDataSourcesShowing = !isDataSourcesShowing" tag="a" class="dropdownToggle">
              Data Sources
              <RocIcon class="toggleArrow" icon="inputArrow" size="xs" color="black" :style="{transform: isDataSourcesShowing ? 'rotate(0deg)' : 'rotate(180deg)'}"/>
            </MDBDropdownToggle>
            <RocDropdownMenu aria-labelledby="dropdownMenuButton" class="dropdownMenu" @click="isDataSourcesShowing = false;">
              <MDBDropdownItem v-if="isPageAllowed('/watchlists')" router :to="{ path: '/watchlists' }">
                Watchlists
              </MDBDropdownItem>
              <MDBDropdownItem v-if="isPageAllowed('/events') && isEventButtonEnabled" router :to="{ path: '/events' }">
                Events
              </MDBDropdownItem>
              <MDBDropdownItem v-if="isPageAllowed('/investigations')" router :to="{ path: '/investigations' }">
                Investigations
              </MDBDropdownItem>
            </RocDropdownMenu>
          </BaseDropdown>
        </MDBNavbarItem>
      </MDBNavbarNav>
      <div class="bento-user">
        <BaseDropdown v-model="isActiveMissionShowing" show align="end">
          <MDBDropdownToggle @click="isActiveMissionShowing = !isActiveMissionShowing" tag="a">
            <div class="active-mission-dropdown">
              <ActiveBubble v-model="activeMission"/>
              <RocIcon
                icon="mission"
                color="black"
                size="sm"
              />
            </div>
          </MDBDropdownToggle>
          <RocDropdownMenu>
            <MDBDropdownItem>
              <div class="active-mission-dropdown-item">
                <div class="title">
                  ACTIVE MISSION
                </div>
                <div class="mission">
                  <span v-if="activeMission">
                    {{ activeMission.name }}
                  </span>
                  <span v-else>
                    No Active Mission
                  </span>
                  <RocIcon
                    icon="edit"
                    color="primary"
                    size="sm"
                    style="cursor: pointer;"
                    @click="goToMissions"
                  />
                </div>

              </div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </BaseDropdown>
        <BaseDropdown v-model="isToolsShowing" show align="end">
          <MDBDropdownToggle @click="isToolsShowing = !isToolsShowing" class="dropdownToggle" tag="a">
            <RocIcon
              icon="bentoBox"
              color="black"
              size="md"
            />
          </MDBDropdownToggle>
          <RocDropdownMenu aria-labelledby="dropdownMenuButton" class="dropdownMenu" @click="isToolsShowing = false">
            <MDBDropdownItem v-if="isPageAllowed('/workflows')" router :to="{path: '/workflows'}">
              Workflows
            </MDBDropdownItem>
            <MDBDropdownItem
              v-if="isPageAllowed('/rocenroll/home') && isEnrollButtonEnabled"
              router
              :to="{path: '/rocenroll/home', query: { flavor: getRocEnrollFlavor() }}"
            >
              Enroll
            </MDBDropdownItem>
            <MDBDropdownItem
              v-if="isPageAllowed('/examine') && isExamineButtonEnabled"
              router
              :to="{path: '/examine'}"
            >
              Examine
            </MDBDropdownItem>
          </RocDropdownMenu>
        </BaseDropdown>
        <BaseDropdown v-model='isUserSettingsShowing' show align="end">
          <MDBDropdownToggle class="nameCircle" tag='button' @click="isUserSettingsShowing = !isUserSettingsShowing">{{userInitials}}</MDBDropdownToggle>
          <RocDropdownMenu aria-labelledby='dropdownMenuButton' class="dropdownMenu" @click="isUserSettingsShowing = false">
            <span v-for="(item, index) in userMenu" :key="item">
              <MDBDropdownItem router :to="item.routerPath" @click="item.clickHandler" style="cursor: pointer;">
                {{ item.label }}
              </MDBDropdownItem>
              <MDBDropdownItem divider v-if="index < (userMenu.length-1)"/>
            </span>
          </RocDropdownMenu>
        </BaseDropdown>
         <router-link class='header-options-dd' to='/License'>
            <RocIcon color="black" icon="help" />
        </router-link>
      </div>
    </MDBNavbar>
    <base-dialog :show='showRetention' title='Retention Settings' @close='showRetention = false;' :style="retentionStyle">
      <retention-settings @close="showRetention = false;"></retention-settings>
    </base-dialog>
    <base-dialog :show="showServerConfig" title="Server Settings" @close="showServerConfig = false;" :style="serverConfigStyle">
      <ServerConfig @close="showServerConfig=false;"/>
    </base-dialog>
    <base-dialog :show="showUISettings" title="UI Settings" @close="showUISettings = false;" :style="uiSettingsStyle">
      <UISettings @close="showUISettings=false;"/>
    </base-dialog>
    <base-dialog :show="showUser" title='Change Password' @close='showUser = false;' :style="passwordChangeStyle">
      <PasswordChange @close="showUser = false" />
    </base-dialog>
  </div>
</template>

<script setup>
import retentionSettings from "@/components/settings/retentionSettings";
import PasswordChange from "@/components/settings/PasswordChange";
import ServerConfig from "@/components/settings/ServerConfig.vue";
import UISettings from "@/components/settings/UISettings.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocPopper from "@/components/ui/RocPopper.vue";

import {
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarNav,
} from "mdb-vue-ui-kit";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { Capacitor } from "@capacitor/core";
import userAccessHelper from "@/js/userAccessHelper";
import RocLogo from "../ui/RocLogo.vue";
import RocIcon from "../ui/RocIcon.vue";
import ActiveBubble from '@/components/ui/ActiveBubble';

const store = useStore();
const router = useRouter();
const isUserSettingsShowing = ref(false);
const showRetention = ref(false);
const showUser = ref(false);
const showServerConfig = ref(false);
const showUISettings = ref(false);
const canWhiteLabel = ref(false);

onMounted(async () => {
  canWhiteLabel.value = store.getters['settings/getLicenseFlags']?.whitelabel ?? true;
});

const isEnrollButtonEnabled = computed(() => {
  return store.getters['settings/isEnrollButtonEnabled'] || isMobilePlatform.value;
});
const isExamineButtonEnabled = computed(() => {
  return store.getters['settings/isExamineButtonEnabled'];
});
const isEventButtonEnabled = computed(() => {
  return store.getters['rocenroll/isEventWorkflowEnabled'];
});

const userInitials = computed(function() {
  return store.getters['auth/userInitials'];
});

/* Dialog Styles */
const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
});

const isMobile = computed(() => {
  return windowWidth.value <= 480;
});

const isMobilePlatform = computed(() => {
  return Capacitor ? Capacitor.getPlatform() != 'web' : false;
});

const retentionStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: '90%'
    }
  } else {
    return {
      width: '450px'
    }
  }
});


const uiSettingsStyle = computed (() => {
  if (windowWidth.value <= 480) {
    return {
      width: '100%',
      'max-height': '100%'
    }
  } else {
    return {
      width: '500px'
    }
  }
});

const serverConfigStyle = computed (() => {
  if (windowWidth.value <= 480) {
    return {
      width: '100%',
      'max-height': '100%'
    }
  } else {
    return {
      width: '900px'
    }
  }
});

const passwordChangeStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: '90%',
    }
  } else {
    return {
      width: '700px'
    }
  }
});

function isPageAllowed(page) {
  return new userAccessHelper().isPageAllowed(page);
}

const userMenu = computed(() => {
  const userMenuItems = [];
  if (isAdmin.value) {
    userMenuItems.push({
      routerPath: "",
      label: "Retention Settings",
      clickHandler: () => { showRetention.value = !showRetention.value; }
    });
    userMenuItems.push({
      routerPath: "/settings/users",
      label: "User Management",
      clickHandler: () => { }
    });
    userMenuItems.push({
      routerPath: "",
      label: "Server Settings",
      clickHandler: () => { showServerConfig.value = !showServerConfig.value; }
    });
    if(canWhiteLabel.value){
      userMenuItems.push({
        routerPath: "",
        label: "UI Settings",
        clickHandler: () => { showUISettings.value = !showUISettings.value; }
      });
    }
  }
  userMenuItems.push({
    routerPath: "",
    label: "Change Password",
    clickHandler: () => { showUser.value = !showUser.value; }
  });

  userMenuItems.push({
    routerPath: "/logout",
    label: "Logout",
    clickHandler: () => {}
  });

  return userMenuItems;
})

const isAdmin = computed(() => {
  try {
    const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
    return userAccessCookie && (userAccessCookie.role === 'admin');
  } catch (err) {
    return false;
  }
});

function getRocEnrollFlavor() {
  return store.getters['rocenroll/enrollDefaultFlavor'] ?? 'rww';
}

const isDataSourcesShowing = ref(false);
const isToolsShowing = ref(false);

const isLiveActiveMission = computed(() => {
  const mission = store.getters['cases/activeMission'];
  return mission?.type === 'live';
})

const activeMission = computed(() => {
  return store.getters['cases/activeMission'];
})
const isActiveMissionShowing = ref(false);
function goToMissions() {
  isActiveMissionShowing.value = false;
  router.push({path: '/missions'});
}

</script>

<style scoped lang="scss">
.navbar {
  height: 60px;
  box-shadow: 0 1px 2px 0 var(--overwatch-overlay);
  background: var(--overwatch-secondary);
}

.navbar-nav {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-xl);
  gap: var(--spacing-xl);
}

.actionItemDropdown {
  border: none !important;
}

.dropdownMenu {
  box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);
}

.dropdownMenu li {
  cursor: pointer;
}

.dropdown-toggle:after {
  display: none;
}
.header-options-dd {
  @include overwatch-title-small;
  color: #000000;
  display: flex !important;
  align-items: center;
}
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.nameCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--overwatch-button-text);
  background: var(--overwatch-primary);

  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
}
.dropdownToggle {
  color: var(--overwatch-neutral-100);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  align-items: center;
  gap: 6px;
}
.actionItem {
  @include overwatch-body-med;

  display: flex;
  align-items: center;

  height: 100%;
  margin: 0;
  list-style-type: none;
  cursor: pointer;
}

.actionItem a {
  color: var(--overwatch-neutral-100);
  user-select: none;
}

.toggleArrow {
  transition: transform 0.3s;
}

a {
  font-weight: unset;
}

.bento-user {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
}

.disabled {
  opacity: .5;
}

.active-mission-dropdown {
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
  gap: var(--spacing-s);
  border: 1px solid var(--overwatch-neutral-400);
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--overwatch-neutral-500);
}

.active-mission-dropdown-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  padding: var(--spacing-m);
  width: 250px;

  .title {
    @include overwatch-title-xsmall;
    color: var(--overwatch-neutral-200);
  }
  .mission {
    @include overwatch-body-med;
    color: var(--overwatch-neutral-100);
    display: flex;
    align-items: center;
    gap: var(--spacing-s);
  }
}

/* MOBILE */
@media (max-width: 480px) {

  .actionItem {
    display: none;
  }
  .actionItem.mobile {
    display: flex;
  }
}
.navbar-nav{
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
</style>
