<template>
  <div style="">
      <search-header class="stick-to-top" :showBack="true" :button-title="btnTitle" :allowPhotoUpload="wltype !== 'text'" 
        :hideSearch="true"
        @button-clicked='importerAdd'
        :hideButton="isMobile"
        :disableButton="isRestricted"
        disableText="Only users with permissions can edit this watchlist. Contact your administrator to request access"
        >
      <template v-slot:leftnav>
        <div style="display:flex; align-items: center; justify-content: space-between;">
          <div style="display:flex; align-items: center;">
            <RocIcon icon="downArrow" class="search-header-backbutton" size="sm" @click="$router.back()"/>
            <span class="search-header-title">{{wlName}}</span>
              <div class="pill">
                <span class="overwatch-title-xsmall d-flex" style="color:#000000; gap: var(--spacing-base)">
                  <RocIcon v-if="wltype === 'face'"  icon="faceSearch" size="sm" :color="watchlistIconColor"/>
                  <RocIcon v-else-if="wltype === 'tattoo'"  icon="gallery" size="sm" :color="watchlistIconColor"/>
                  <RocIcon v-else icon="vehicle" size="sm" :color="watchlistIconColor"/>
                  {{totalWatchlistedElements}}
                </span>
              </div>
          </div>
          <div>
            <RocButton v-if="isMobile" type="primary" @click.stop="importerAdd"> {{ btnTitle }}</RocButton>
          </div>
        </div>
      </template>
    </search-header>
    <div class="d-flex justify-content-end" style="margin-right: 20px; margin-top: 20px">
      <RocInput
        :placeholder="placeholder"
        type="text"
        v-model.lazy.trim="filterText"
        :disabled="searchImageMode"
      >
      <RocIcon color="black" icon="search" size="sm" style="margin-right: 10px"/>
      <template #customIcon>
        <MDBDropdown v-if="wltype !== 'text' && !searchImageMode" v-model='dropdownOptions'>
          <MDBDropdownToggle
            style='color:black;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;'
            tag='a'
            @click="dropdownOptions = !dropdownOptions">
            <RocIcon color="primary" icon="image" size="sm"/>
          </MDBDropdownToggle>
          <MDBDropdownMenu @click="dropdownOptions=false" aria-labelledby='dropdownMenuButton' style="margin-top: 20px; box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
            <MDBDropdownItem href="#" @click.stop>
              <div class="imageSearch" >
                Search by image
                <div>
                  <RocDragAndDrop @onDrop="imageSelected" :oneFile="true"/>
                </div>
                <div class="d-flex justify-content-end">
                  <RocButton @click.prevent="handleImageSearch" :disabled="enabledIMGSearch">
                    Search
                  </RocButton>
                </div>
              </div>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <RocIcon v-if="wltype !== 'text' && searchImageMode" icon="exit" size="sm" style="cursor: pointer;" @click="clearSearchImageMode"/>
      </template> 
      </RocInput>
    </div> 
    <div class="d-flex examineSelectImages" v-if="searchImageMode && !isLoading && isExamineButtonEnabled && wltype === 'face'">
      <div class="d-flex flex-column">
        <RocButton style="margin: 5px;" @click="handleSelectingImages">Select</RocButton>
      </div>
      <div class="d-flex flex-column">
        <RocButton v-if="!caseLoading" :disabled="examineImages.length < 1" @click="startExamineCase"
          style="margin: 5px;">Start Case</RocButton>
        <RocButton v-else style="margin: 5px;"><roc-spinner size="sm" style="width: auto !important; height: auto !important; margin: 0;" /></RocButton>
      </div>
    </div>
    <div class="d-flex" :style="styleContent">
      <!-- Search face image mode exposes a left side DIV for seeing probe and side-by-side matches -->
      <div v-if="searchImageMode" class="d-flex flex-column" :style="styleSectionSearchProbe">
        <div class="search-subtitle">{{ searchSubtitle }}</div>
        <div class="d-flex flex-row justify-content-center">
          <div class="d-flex flex-column">
            <div style="position: relative;">
              <img class="search-image"
                :src="searchImageDataProbe"
                style="cursor: pointer;
                margin-right: 25px;"
                @click="showSearchProbeDetails=true"
              />
              <RocIcon icon="expand" class="image-expand-icon" :size="isMobile ? 'sm' : 'lg'" @click="showSearchProbeDetails=true"/>
            </div>
            <div class="search-image-probe-label">Uploaded Image</div>
            <div class="search-image-probe-details">{{ searchImageFilename }}</div>
          </div>
          <div v-if="searchImageDataCandidate" class="d-flex flex-column">
            <div style="position: relative;">
              <auth-img class="search-image" :src="searchImageDataCandidate.enrollment_image_preview"
                style="cursor: pointer;" @click="toggleShowImageDetailsCandidate(searchImageDataCandidate)" />
              <RocIcon icon="expand" class="image-expand-icon" :size="isMobile ? 'sm' : 'lg'"
                @click="toggleShowImageDetailsCandidate(searchImageDataCandidate)" />
            </div>
            <div class="search-image-candidate-label">{{ searchImageDataCandidate.firstname }}
              {{ searchImageDataCandidate.lastname }}</div>
            <div v-if="searchImageDataCandidate.similarity || searchImageDataCandidate.similarity == 0"
              class="search-image-candidate-details">Similarity Score {{ require('@/js/rocHelper').formatSimilarityScore(searchImageDataCandidate.similarity) }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column" style="flex: 7">
        <InfiniteScroll
          class="infiniteScroll"
          :items="watchlistedElements"
          :enabled=true
          :max-pages="totalPages"
          @refetch="getWatchlistedElements"
          :page="currentPage"
          @setPage="setCurrentPage"
          style="margin-left: 1.5rem; margin-top: 1rem;"
        >
          <template v-slot:item="{ item }">
            <watchlisted-license-plate v-if="wltype === 'text'" :watchlisted-plate="item"
              :restricted="isRestricted"
              @toggleEnabled="toggleEnabled" @deleteElement="showDeleteConfirmation(item)"
              @moveElement="toggleMoveWatchlistedLpr(item)" @showDetails="toggleShowLicensePlateDetails(item)" />
            <watchlisted-image v-else 
              :watchlisted-face="item"
              :type="wltype"
              :restricted="isRestricted"
              :selectingImages="selectingImages" :canSelect="isImageSelectable(item)"
              @addedToCase="handleCaseImageAdd(item)" @removedFromCase="handleCaseImageRemove(item)"
              @toggleEnabled="toggleEnabled" @deleteElement="showDeleteConfirmation(item)"
              @showDetails="toggleShowImageDetails(item)" @moveElement="toggleMoveWatchlistedFace(item)" />
          </template>
        </InfiniteScroll>
        <!-- No Matches Found Placeholder div when in search face mode -->
        <div v-if="searchImageMode && !isLoading && watchlistedElements.length == 0"
          class="d-flex flex-column justify-content-center no-data-placeholder">
          <div style="width: 100%; margin-bottom: 10px;">
            <RocLogo />
          </div>
          <div>
            <div style="width: 100%">No Matches Found</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if='isLoading' style="display: flex; justify-content: center; align-items: center;">
      <roc-spinner/>
    </div>
    <base-dialog :show='showImageDetails' :title="watchlistedImageDetailsTitle" @close='hideShowImageDetails'
      :style="faceDetailsStyle">
      <watchlisted-image-details :read-only="searchImageMode" :watchlisted-face-id="showImageDetailsElement._id"
        :type="wltype"
        :md="showImageDetailsElement.md" :first-name="showImageDetailsElement.firstname"
        :last-name="showImageDetailsElement.lastname" :notes="showImageDetailsElement.notes"
        :internal-id="showImageDetailsElement.internalId" :image-path="showImageDetailsElement.enrollment_image_preview"
        :raw-object="showImageDetailsElement" :show-meta-data="showImageDetailsElement.searchProbe"
        :show-encounter-matches-mode="showImageDetails" @update="hideShowImageDetails(true)">
      </watchlisted-image-details>
    </base-dialog>
    <base-dialog
      :show="showMoveElement"
      title="Move to..."
      @close="hideMoveWatchlistedElement"
      :style="moveElementStyle"
    >
      <EnrollEncounter
        mode="move"
        :faceThumbnail="showImageDetailsElement.enrollment_image_preview" 
        :firstName="showImageDetailsElement.firstname"
        :lastName="showImageDetailsElement.lastname"
        :moveWatchlistedElementId="showImageDetailsElement._id"
        :moveWatchlistedElementCurrentWatchlist="wlName"
        :moveWatchlistedElementWatchlistType="wltype"
        @elementMoved="handleElementDeleted"
      />
    </base-dialog>
    <base-dialog :show="showMoveElement" title="Move Enrollment" @close="hideMoveWatchlistedElement"
      :style="moveElementStyle">
      <EnrollEncounter mode="move" :faceThumbnail="showImageDetailsElement.enrollment_image_preview"
        :firstName="showImageDetailsElement.firstname" :lastName="showImageDetailsElement.lastname"
        :moveWatchlistedElementId="showImageDetailsElement._id" :moveWatchlistedElementCurrentWatchlist="wlName"
        :moveWatchlistedElementWatchlistType="wltype" @elementMoved="handleElementDeleted" />
    </base-dialog>
    <!-- show a side by side dialog view of the search source image and the cropped face with quality score -->
    <base-dialog
      :show='showSearchProbeDetails'
      :title="'Source Image'"
      @close='showSearchProbeDetails=false'
      :style="searchProbeDetailsStyle"
    >
      <div class="search-probe-dialog">
        <img :src="searchImageDataSourceImage" class="search-probe-dialog-img" />
        <img :src="searchImageDataProbe" class="search-probe-dialog-img" />
      </div>
      <div style="width: 100%; text-align: center;" class="overwatch-body-med">
        Image Quality: <b>{{ (searchImageTemplateProbe.md.Quality * 100).toFixed(0) }}</b>
      </div>
    </base-dialog>
    <base-dialog
      :show="isShowingDelete"
      @close="isShowingDelete=false"
      :style="deleteConfirmationStyle">
      <DeleteConfirmation
        @close="isShowingDelete=false"
        @delete="deleteElement(currentElement)"
        :message="'Yes'"
        :cancelMessage="'No'"

      >
      <div class="d-flex">
          <RocIcon icon="trash" color="red" style="margin-right: var(--spacing-m);"></RocIcon>
        <div>
          Are you sure you want to delete <span style="text-decoration: underline;">
            {{currentElement.fullname ? currentElement.fullname :
              (currentElement.plateNumber ? currentElement.plateNumber :
                currentElement.firstname + ' ' + currentElement.lastname)
            }}
            </span>
        </div>
      </div>
      </DeleteConfirmation>
    </base-dialog>
    <RocToast v-if="displayToast" :message="message" @autoClose="() => {displayToast = !displayToast}"></RocToast>
  </div>
</template>

<script setup>
    import { computed, onMounted, ref, defineProps, watch, defineExpose } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import { useStore } from "vuex";
    import InfiniteScroll from "@/components/ui/InfiniteScroll";
    import SearchHeader from "@/components/ui/SearchHeader";
    import WatchlistedImage from "@/components/watchlists/WatchlistedImage";
    import WatchlistedImageDetails from "@/components/watchlists/WatchlistedImageDetails";
    import WatchlistedLicensePlate from "@/components/watchlists/WatchlistedLicensePlate";
    import WatchlistedLicensePlateDetails from "@/components/watchlists/WatchlistedLicensePlateDetails";
    import EnrollEncounter from "@/components/watchlists/EnrollEncounter";
    import BaseDialog from "@/components/ui/BaseDialog";
    import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
    import { ValidateImage } from '../../js/fileHelper';
    import dateHelper from '../../js/dateHelper';
    import userAccessHelper from "@/js/userAccessHelper";
    import RocIcon from '@/components/ui/RocIcon';
    import RocLogo from '@/components/ui/RocLogo';
    import RocButton from '@/components/ui/RocButton';
    import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle,MDBDropdownMenu } from "mdb-vue-ui-kit";
    import RocToast from "../../components/ui/RocToast.vue";
    import RocInput from '@/components/ui/RocInput';
    import RocDragAndDrop from '@/components/ui/RocDragAndDrop';

    const props = defineProps({ id: String, wltype: String })

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const watchlistedElements = ref([]);
    const watchlistId = ref(props.id);
    const isLoading = ref(false);
    const totalPages = ref();
    const totalWatchlistedElements = ref();
    const wlName = ref(route.query.wlname);
    const showOptions = ref(false);
    const filterText = ref('');
    const currentPage = ref(1);
    const showAddFaces = ref(false);
    const btnTitle = ref('');
    let searchTextInputTimeout = null;
    const wlMatchThreshold = ref(0);

    const showImageDetails = ref(false);
    const showImageDetailsElement = ref();
    const showLicensePlateDetails = ref(false);
    const showLicensePlateDetailsElement = ref();

    const searchImageDataSourceImage = ref('');
    const searchImageFile = ref();
    const searchImageDataProbe = ref('');
    const searchImageDataCandidate = ref(null);
    const searchImageMode = ref(false);
    const searchImageTemplateProbe = ref(null);
    const showSearchProbeDetails = ref(false);
    const searchImageFilename = ref('');
    const statusColor = ref('');

    const isShowingDelete = ref(false);
    const currentElement = ref();

    const selectingImages = ref(false);
    const examineImages = ref([]);
    const caseLoading = ref(false);

    const displayToast = ref(false);
    const message = ref(" ");
    const dropdownOptions = ref(false);
    const enabledIMGSearch = ref(true);
    const image = ref(null);
    const result = ref(null);
    const imgName = ref(null);
    const placeholder = ref('');

    const windowWidth = ref(window.innerWidth);

    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    })

    const showMoveElement = ref(false);

    const isRestricted = computed(() => {
      const wl = store.getters['watchlists/watchlists'].filter(item => item._id === route?.params?.id)[0];
      return wl?.restricted;
    })

    onMounted(() => {
      window.setLoading = () => caseLoading.value = !caseLoading.value;

      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });

    });

    if (props.wltype === 'face') {
      btnTitle.value = 'Add Face';
      placeholder.value = 'Search Faces';
      store.commit("watchlists/setImporterType", 'face');
    }
    else if (props.wltype === 'text') {
      btnTitle.value = 'Add Plate';
      placeholder.value = 'Search License Plates';
      store.commit("watchlists/setImporterType", 'text');
    } else {
      btnTitle.value = 'Add Tattoo';
      placeholder.value = 'Search Tattoos';
      store.commit("watchlists/setImporterType", 'tattoo');
    }

    const isExamineButtonEnabled = computed(() => {
      return store.getters['settings/isExamineButtonEnabled'];
    });

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

    const watchlistIconColor = computed(() => {
          if(darkMode.value) return 'white';
          return 'black';
    });

    function isImageSelectable(item) {
      const idx = examineImages.value.findIndex(elem => elem._id === item._id);
      if(examineImages?.value?.length <= 4){
        return true;
      } else {
        return idx !== -1;
      }
    }

    function handleSelectingImages() {
      if (selectingImages.value === true) {
        examineImages.value = [];
      }
      selectingImages.value = !selectingImages.value
    }

    function handleCaseImageAdd(item) {
      examineImages.value.push(item);
    }

    watch(filterText, () => {

      if(!filterText.value.includes('.')) //When image is uploaded but not searched, dont reset filter
      {
        if(searchTextInputTimeout) {
          clearTimeout(searchTextInputTimeout);
        }
        searchTextInputTimeout = setTimeout(()=> {
          resetWatchlistedElements();
          getWatchlistedElements(1, props.wltype);
        }, 500);
        }
    });

    function imageSelected(imageFile){
      let tempImage = imageFile[0];

      if (tempImage) {
        if (tempImage.type.startsWith("image")) {
          let fr = new FileReader();
          fr.onloadend = function () {
            image.value = tempImage;
            result.value = fr.result;
            imgName.value = tempImage.name;
            enabledIMGSearch.value = false;
            filterText.value = imgName.value;
          };
          fr.onerror = function () {
            console.error("Unable to load selected image");
          };
          fr.readAsDataURL(tempImage);
        }
      }
    }

    function handleImageSearch() {
      // cache uploaded image as probe and source image
      searchImageFile.value = image.value;
      searchImageDataProbe.value = result.value;
      searchImageDataSourceImage.value = result.value;
      // enable search image mode for UX updates
      searchImageMode.value = true;
      // cache filename of uploaded image for UX
      searchImageFilename.value = imgName.value;
      runSearchImage();
    }

    function clearSearchImageMode() {
      // disable search image mode for UX updates
      filterText.value='';
      searchImageFile.value = null;
      enabledIMGSearch.value = true;
      searchImageMode.value = false;
      // reset cached image data
      searchImageDataProbe.value = '';
      searchImageDataCandidate.value = null;
      searchImageTemplateProbe.value = null;
    }

    async function runSearchImage() {
      // we're not including filter text when searching with image
      filterText.value = searchImageFilename.value;
      resetWatchlistedElements();
      //changing filter text will reset the watchlist
      getWatchlistedElements(1, props.wltype);
    }

    function handleCaseImageRemove(item){
      const id = examineImages.value.findIndex(elem => elem._id === item._id);
      examineImages.value.splice(id, 1);
    }

    async function processExamineImages() {
      const images = [];
      for (const [index, element] of examineImages.value.entries()) {
        const image = fetch(element.enrollment_image)
          .then(res => res.blob())
          .then(async (blob) => {
            const file = new File([blob], `${element.firstName}-${index}.jpg`);
            const validator = new ValidateImage(file, { extraProps: { imageType: 'Source', knownStatus: 'Known' } });
            return validator.validate();
          })
        images.push(image);
      }

      const validator = new ValidateImage(searchImageFile.value, { extraProps: { imageType: 'Source', knownStatus: 'UnKnown' } });
      const probe = validator.validate();
      images.push(probe)

      return await Promise.all(images);
    }

    async function startExamineCase() {
      caseLoading.value = true;

      const caseImages = await processExamineImages();
      const user = store.getters['auth/email'];
      const date = new dateHelper().formatDateMinutes();
      const caseName = `WATCH-${date}`;
      let workflow = 'R';
      const payload = {
        examiner: user,
        name: caseName,
        workflow: workflow,
        images: caseImages,
      };

      store.dispatch('tools/createCase', payload)
      .then(data => {
        caseLoading.value = false;
        if(data.status === 'success'){
          store.commit('tools/setReadOnly', { readOnly: false })
          router.push({ name: 'Examination', params: { caseName: data.caseName, examId: data.examId } });
        }
      })
    }

    getWatchlistedElements(1, props.wltype);
    async function getWatchlistedElements(payload, type = 'face') {
      isLoading.value = true;
      const getPayload = {
        type: type,
        watchlistId: watchlistId.value,
        currentPage: currentPage.value,
        filterText: filterText.value,
        filterImage: searchImageMode.value ? searchImageDataProbe.value : '',
        matchThreshold: wlMatchThreshold.value,
      }
      const responseData = await store.dispatch("watchlists/getWatchlistElements", getPayload);
      if (responseData) {
        if (responseData.status == 'failed') {
          console.error(responseData.message);
        } else {
          totalPages.value = responseData.totalPages;
          if (responseData.elements) {
            responseData.elements.forEach((element) => {
              watchlistedElements.value.push(element);
            });
          }
          totalWatchlistedElements.value = responseData.totalWatchlistedElements;
          if (searchImageMode.value) {
            if (responseData.probeTemplate) {
              // cache the probe template here for UX use as needed - for now we show the cropped thumbnail so the user clearly
              // sees the face probe that was searched in the event that there are more faces in the image
              searchImageTemplateProbe.value = responseData.probeTemplate;
              const tnUrl = responseData.probeTemplate.tn;
              searchImageDataProbe.value = (tnUrl.startsWith("data:image")) ? tnUrl : "data:image/jpg;base64," + responseData.probeTemplate.tn;
            }
          }
        }
      } else {
        console.error("Failed to query watchlisted elements, check filters");
      }
      isLoading.value = false;
      payload.status ? payload.status.value = false : 0;
    }

    async function updateWatchlistedElement(watchlistedElement) {
      const payload = {
        type: props.wltype,
        watchlistedElement: watchlistedElement
      };
      const responseData = await store.dispatch('watchlists/updateWatchlistedElement', payload);
      if (responseData && responseData.status == "success") {
        watchlistedElement.value = responseData.watchlistedElement;
      }
    }

    async function deleteWatchlistedElement(elementId) {
      console.log("Delete watchlisted element ")
      const deletePayload = {};
      deletePayload.watchlistedElementId = elementId;
      deletePayload.watchlistType = props.wltype;
      const responseData = await store.dispatch('watchlists/deleteWatchlistedElement', deletePayload);
      if (responseData && responseData.status == "success") {
        handleElementDeleted(elementId,'deleted');
      } else {
        // TODO show any error indication to user?  or delete just doesn't occur
        console.error(`deleteWatchlistedElement::Unable to delete watchlisted element ${deletePayload.watchlistedElementId}`);
      }
    }


    function resetWatchlistedElements() {
      watchlistedElements.value = [];
      currentPage.value = 1;
    }
    
    async function toggleEnabled(payload) {
      await updateWatchlistedElement(payload);
    }

    async function deleteElement(element) {
      const id = element._id;
      await deleteWatchlistedElement(id);
    }

    function showDeleteConfirmation(item) {
      currentElement.value = item;
      isShowingDelete.value = true;
    }

    function setCurrentPage(pageNum) {
      currentPage.value = pageNum;
    }

    function toggleShowImageDetails(item) {
      // asynchonously query the full details here, which will reactively update the dialog
      // this accomplishes a higher quality thumbnail image without user interruption
      getWatchlistedImageDetails(item);
      if (searchImageMode.value) {
        searchImageDataCandidate.value = item;
      } else {
        showImageDetailsElement.value = item;
        showImageDetails.value = true;
      }
    }

    function toggleShowLicensePlateDetails(item) {
      showLicensePlateDetailsElement.value = item;
      showLicensePlateDetails.value = true;
    }

    function hideShowImageDetails(updated = false) {
      showImageDetailsElement.value = null;
      showImageDetails.value = false;
      // if we're updating
      if (updated) {
        resetWatchlistedElements();
        getWatchlistedElements(1, props.wltype);
      }
    }

    function toggleMoveWatchlistedFace(item) {
      showImageDetailsElement.value = item;
      showMoveElement.value = true;
    }

    function toggleMoveWatchlistedLpr(item) {
      // TODO
    }

    function hideMoveWatchlistedElement() {
      showImageDetailsElement.value = null;
      showMoveElement.value = false;
    }

    function handleElementDeleted(elementId,actionType) {
      const index = watchlistedElements.value.findIndex((element) => element._id === elementId);

      if(index >= 0 && watchlistedElements.value[index]?.fullname)
      {
        message.value = watchlistedElements.value[index].fullname + ` has been ${actionType}`;
        watchlistedElements.value.splice(index, 1);
        // decrement total count since we deleted an element
        totalWatchlistedElements.value--;
        displayToast.value = true;
      }
      else if (index >= 0) {
        message.value = watchlistedElements.value[index].firstname + ' ' + watchlistedElements.value[index].lastname + ` has been ${actionType}`;
        watchlistedElements.value.splice(index, 1);
        // decrement total count since we deleted an element
        totalWatchlistedElements.value--;
        displayToast.value = true;
      }
    }

    const faceDetailsStyle = computed(() => {
      if (isMobile.value) {
        return {
          'max-height': '95%',
          'width': '90%',
          'overflow-y': 'auto',
        }
      } else {
        return {
          // 'width': '800px',
          'max-height': '95%',
          'max-width': '95%',
          'overflow-y': 'auto',
        }
      }
    });

    const moveElementStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          'width': '400px !important',
          'max-height':'100%',
        }
      } else {
        return {
          'width': '400px !important',
          'overflow': 'visible',
        }
      }
    });

    // load watchlists here if needed - a page refresh of this page will cause vuex stored watchlists to empty
    // and this is needed for the watchlist move menu.
    loadWatchlists();
    async function loadWatchlists() {
        let watchlistList = store.getters["watchlists/watchlists"];
        if (!watchlistList || watchlistList.length === 0) {
        // if empty, double check we didn't lose vuex cache by reloading
            await store.dispatch('watchlists/loadWatchlists');
            watchlistList = store.getters["watchlists/watchlists"];
        }
        watchlistList.forEach((item) => {
            if(item._id === watchlistId.value){
                wlMatchThreshold.value = item.matchThreshold;
                statusColor.value = item.color;
            }
        })
    }

    async function getWatchlistedImageDetails(watchlistedFace) {
      const dispatchUrl = props.wltype === 'face' ? 'watchlists/getWatchlistedFace' : 'watchlists/getWatchlistedImage';
      const responseData = await store.dispatch(
        dispatchUrl,
        { id: watchlistedFace._id }
      );
      const responseKey = (props.wltype === 'face') ? 'face' : 'image';
      if (responseData && responseData.status == 'success') {
        // overwrite the preview with the higher quality thumbnail
        responseData[responseKey].enrollment_image_preview = responseData[responseKey].tn;
        if (searchImageMode.value) {
          // if we're in search mode and we've queried face details, this is the candidate
          searchImageDataCandidate.value = responseData[responseKey];
          searchImageDataCandidate.value.similarity = watchlistedFace.matchDetails.similarity;
        } else {
          // if we're not in search mode and we've queried face details, this is details on an enrollment
          showImageDetailsElement.value = responseData[responseKey];
        }
      }
    }
    const styleContent = computed(() => {
      // using larger width than mobile here - layout should stack sooner
      if (windowWidth.value <= 900) {
        return {
          'flex-direction': 'column',
        };
      } else {
        return {
          'flex-direction': 'row',
          // 'height': '100vh'
        };
      }
    });

    const styleSectionSearchProbe = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          'padding-bottom': '0.5rem',
          'padding-left': '1rem',
          'padding-right': '1rem',
          'margin-top' : '0.5rem',
          'background': 'var(--overwatch-secondary)',
          'margin-left' : '0.5rem',
          'margin-right' : '0.5rem',
        };
      } else {
        return {
          'padding-bottom': '2rem',
          'padding-left': '2rem',
          'padding-right': '2rem',
          'flex': '2',
          'background': 'var(--overwatch-secondary)',
          'margin-left' : '1rem',
          'margin-top' : '1rem',
        };
      }
    });

    const searchSubtitle = computed(() => {
      if (searchImageMode.value && watchlistedElements.value.length) {
        return windowWidth.value <= 480 ?
          'Select a candidate below to inspect the match' :
          'Select a candidate on the right to inspect the match';
      } else {
        return '';
      }
    });


    const searchProbeDetailsStyle = computed(() => {
      if (isMobile.value) {
        return {
          width: '90%',
        }
      } else {
        return {
          width: '700px',
          height: '550px',
        }
      }
    });

    function toggleShowImageDetailsCandidate(item) {
      showImageDetailsElement.value = item;
      showImageDetails.value = true;
    }

    const watchlistedImageDetailsTitle = computed(() => {
      if (searchImageMode.value) {
        return 'Candidate Details';
      } else {
        return 'Enrollment Details';
      }
    });

    const deleteConfirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%'
        }
      }
    });
    function importerAdd() {
      store.dispatch("watchlists/initWatchlistImporter", {
        type: props.wltype,
        mode: props.wltype,
        watchlistId: props.id,
        visible: true
      });
    }

    function importerClose() {
      store.dispatch("watchlists/toggleWatchlistImporter", false);
      resetWatchlistedElements();
      getWatchlistedElements(1, props.wltype);
    }

    defineExpose({
      importerClose
    });
</script>

<style scoped lang="scss">
.examineSelectImages {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 4rem;
  padding-top: 1rem;
}

.watchlist-size {
  height: 28px;
  width: 29px;
  opacity: 0.5;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  line-height: 30px;
  text-align: left;
}

.pill{
    margin-left: 10px;
    display: flex;
    gap: var(--spacing-base);
    margin-right: 0px;
    align-items: center;
    line-height: 48px;
    border-radius: 48px;
    width: 60px;
    padding: 10px;
    background-color: v-bind(statusColor);
    height: 20px;
    color:gray;
    width: fit-content;
}

.search-image {
  height: 250px;
  width: auto;
}

img {
  max-height: 100%;
}

.search-subtitle {
  @include overwatch-body-small;
  padding-bottom: 1rem;
  margin-top: 10px;
  margin-left: 10px;
}

.watchlist-title {
  @include overwatch-title-large;
  padding-left: 1rem;
  padding-top: 0.5rem;
  color: black;
}

.imageSearch{
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.watchlist-details {
  @include overwatch-body-med;
  padding-right: 2rem;
  padding-top: 1rem;
  color: var(--overwatch-neutral-100);
  opacity: 0.6;
  flex-grow: 1;
  text-align: end;
}

.search-probe-dialog {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.search-probe-dialog-img {
  flex: 1;
  max-height: 400px;
  max-width: 50%;
  object-fit: contain;
  padding: 10px;
}

.infiniteScroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.search-image-probe-label {
  opacity: 0.5;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  font-weight: 600;
}

.search-image-probe-details {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-small;
}

.search-image-candidate-label {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
}

.search-image-candidate-details {
  width: auto;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
  border: 1px solid var(--overwatch-neutral-100);
  background-color: var(--roc-watch-similarity-90);
  ;
  user-select: none;
}

.search-back {
  cursor: pointer;
  margin-left: var(--spacing-l);
  margin-right: var(--spacing-l);
}

.search-header-title {
  font-size: 20px;
}

.search-header-backbutton {
  cursor: pointer;
  margin-right: var(--spacing-l);
  transform: rotate(90deg);
}

.image-expand-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
  cursor: pointer;
  /* this adds a semitransparent background effect, helpful for white images */
  /* padding: 5px;
  background: rgba(0, 0, 0, 0.1); */
}

.no-data-placeholder {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-small;
  text-align: center;
  margin-top: 50px;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

/* MOBILE */
@media (max-width: 480px) {
  .search-image {
    height: 125px;
  }

  .search-subtitle {
    font-size: var(--spacing-s);
    padding-bottom: 0.3rem;
    margin-top: 0px;
    margin-left: 10px;
  }

  .watchlist-title {
    font-size: 20px;
  }

  .watchlist-details {
    font-size: 14px;
    padding-right: 1rem;
  }

  .search-probe-dialog {
    flex-direction: column;
  }
  .infiniteScroll{
    justify-content: center;
    gap: var(--spacing-base);
  }
  .search-probe-dialog-img {
    max-height: 250px;
    max-width: 100%;
  }

  .search-image-probe-label {
    font-size: var(--spacing-s);
  }

  .search-image-probe-details {
    font-size: 10px;
  }

  .search-image-candidate-label {
    font-size: var(--spacing-s);
  }

  .search-image-candidate-details {
    height: var(--spacing-l);
    font-size: 10px;
    border-radius: var(--spacing-s);
    padding: var(--spacing-base);
  }

  .search-back {
    margin-left: 0px;
    margin-right: 1rem;
  }

  .search-header-title {
    font-size: 18px;
  }

  .search-header-backbutton {
    margin-right: 14px;
  }

  .image-expand-icon {
    left: 5px;
    top: 5px;
  }

  .no-data-placeholder {
    font-size: 20px;
    margin-top: 25px;
  }

  .no-data-placeholder img {
    height: 150px;
  }
}
</style>
