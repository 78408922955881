export default {
  cases(state) {
    return state.cases;
  },
  cameras(state) {
    return state.cameras;
  },
  importerVisible(state) {
    return state.importerVisible;
  },
  editingCase(state) {
    return state.editingCase;
  },
  isCaseProcessing(state) {
    return state.isCaseProcessing;
  },
  encounterFilterSelectedCase(state){
    return state.encounterFilterSelectedCase;
  },
  getDateTimeParser(state){
    return state.dateTimeParserValue;
  },
  activeMission(state) {
    return state.activeMission;
  },
  selectedTable(state) {
    return state.selectedTable;
  },
  loadedOnce(state) {
    return state.loadedOnce;
  }
}