class versionInfo {
    constructor() {
        this.version = "master";
        this.revision = "a113d374bc78bf97cb6f5d6e7c132f63b55b903e";
        this.commitCount = "0";
        this.buildDate = "Fri Aug  2 01:56:43 UTC 2024";
        this.isProduction = !this.version.startsWith("{{"); // The script for building the production container will substitute the above values

        if (!this.isProduction) {
            // For dev environment determine version from git
            try {
                this.version = require('child_process')
                               .execSync('git rev-parse --abbrev-ref HEAD')
                               .toString().trim();
            } catch(err) {
                this.version = "DEV";
            }

            try {
                this.revision = require('child_process')
                                .execSync('git rev-parse HEAD')
                                .toString().trim();
            } catch(err) {
                this.revision = "DEV";
            }

            try {
                this.commitCount = require('child_process')
                                   .execSync('git rev-list --count origin/master..')
                                   .toString().trim();
            } catch(err) {
                this.commitCount = "DEV";
            }

            this.buildDate = new Date().toUTCString();
        }
    }
}

module.exports = versionInfo;
