<template>
  <div v-if="taggingMode === 'simple' && tagGroupInstances[0]"
  class="tagging-filter-wrapper"
  style="gap: var(--spacing-s);"
  >
    <TagGroupMultiselect
      style="display: flex; flex: 1; width: 100%;"
      mode="tags"
      ref="simpleTagGroupInstanceRef"
      :andOrSelected="tagGroupInstances[0].andOrMenuSelectionText"
      :no-results-text="'No results found'"
      :placeholder-text="tagsPlaceholderText"
      :available-items="allAvailableAttributes"
      :currently-selected="tagGroupInstances[0].selectedTags"
      :groups-enabled="true"
      @selection-changed="updateAttributesFilter"
    />
    <!-- render plus badge if no selected tags exist -->
    <RocIcon v-if="tagGroupInstances[0].selectedTags && tagGroupInstances[0].selectedTags.length === 0" size="md" color="primary" icon="addButton"
      style="cursor: pointer;" @click="simpleTagGroupInstanceRef.enableSelf()" />
    <MDBDropdown v-model="tagGroupInstances[0].andOrDropdownToggle" align="end" class="andOr-button-menu-container">
      <RocDropdownToggle @click="tagGroupInstances[0].andOrDropdownToggle = !tagGroupInstances[0].andOrDropdownToggle">
        <div class="andOr-dropdown-button">
          <RocButton :size="'tiny'" style="text-transform: capitalize !important; width: fit-content">
            {{ tagGroupInstances[0].andOrMenuSelectionText === 'or' ? 'any' : 'all' }}
            <RocIcon
              icon="inputArrow"
              size="xxs"
              color="white"
              style="margin-left:var(--spacing-base); transition: transform 0.1s ease;"
              :style="{transform: tagGroupInstances[0].andOrDropdownToggle ? 'rotate(0deg)' : 'rotate(180deg)'}"
            />
          </RocButton>
        </div>
      </RocDropdownToggle>
      <RocDropdownMenu aria-labelledby='dropdownMenuButton' style="margin-top: var(--spacing-base);">
        <MDBDropdownItem href="#" @click.prevent="andOrSelectionHandler(0, tagGroupInstances[0].andOrMenuSelectionOption)">
          <div class="ROC Global-body-small" style="text-transform: capitalize !important;">
            {{ tagGroupInstances[0].andOrMenuSelectionOption === 'or' ? 'any' : 'all' }}
          </div>
        </MDBDropdownItem>
      </RocDropdownMenu>
    </MDBDropdown>
    <div style="padding: var(--spacing-base); cursor: pointer;">
      <RocIcon color="black" size="xs" icon="exit" @click="simpleTagGroupInstanceRef.clearOptions()" />
    </div>
  </div>
  <div v-else-if="taggingMode === 'advanced' && tagGroupInstances.length"
    v-for="(item, index) in tagGroupInstances" :key="item.id"
    style="display: flex; width: 100%; align-items: center; margin-top: var(--spacing-base)">
    <div>
      <MDBDropdown v-model="item.andOrDropdownToggle" align="start" class="andOr-button-menu-container">
        <RocDropdownToggle @click="item.andOrDropdownToggle = !item.andOrDropdownToggle">
          <div class="andOr-dropdown-button">
            <RocButton :size="'tiny'" style="text-transform: capitalize !important;">
              {{ item.andOrMenuSelectionText }}
              <RocIcon
              icon="inputArrow"
              size="xxs"
              color="white"
              style="margin-left:var(--spacing-base); transition: transform 0.1s ease;"
              :style="{transform: item.andOrDropdownToggle ? 'rotate(0deg)' : 'rotate(180deg)'}"
              />
            </RocButton>
          </div>
        </RocDropdownToggle>
        <RocDropdownMenu aria-labelledby='dropdownMenuButton' style="margin-top: var(--spacing-base);">
          <MDBDropdownItem href="#" @click.prevent="andOrSelectionHandler(index, item.andOrMenuSelectionOption)">
            <div class="overwatch-body-small" style="text-transform: capitalize !important;">
              {{ item.andOrMenuSelectionOption }}
            </div>
          </MDBDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>
    </div>
    <!-- add an index to each of props so they can be treated as individuals -->
    <div class="tagging-filter-wrapper" style="flex-grow: 1;">
      <TagGroupMultiselect
      style="display: flex; flex: 1; width: 100%;"
      mode="tags"
      ref="advancedTagGroupInstanceRef"
      :index="index"
      :andOrSelected="item.andOrMenuSelectionText"
      :no-results-text="'No results found'"
      :placeholder-text="tagsPlaceholderText"
      :available-items="allAvailableAttributes"
      :currently-selected="item.selectedTags"
      :groups-enabled="true"
      @selection-changed="updateAttributesFilter"
      />
      <RocIcon v-if="item.selectedTags && item.selectedTags.length === 0" size="md" color="primary" icon="addButton"
        style="cursor: pointer; margin: 0; margin-left: 3px" @click="advancedTagGroupInstanceRef[index].enableSelf()" />
      <div style="padding: var(--spacing-xs); cursor: pointer;">
        <RocIcon color="black" size="xs" icon="exit" @click="advancedTagGroupInstanceRef[index].clearOptions()" />
      </div>
    </div>
    <div>
      <RocIcon style="cursor: pointer; margin-left: var(--spacing-base)" color="red" size="sm" icon="trash"
        @click="removeTagGroup(index)" />
    </div>
  </div>
  <div class="tagging-button-container">
    <div class="tagging-view-toggle-text" :class="{ 'disabled': tagGroupInstances.length > 1 }"
      @click="toggleTaggingMode()">{{ taggingViewToggleText }}</div>
    <RocButton v-if="taggingMode === 'advanced'" style="margin-left: var(--spacing-s);" type="secondary"
      @click="createNewTagGroup()">
      Add Tag Group
    </RocButton>
  </div>
</template>

<script>
import TagGroupMultiselect from '@/components/ui/TagGroupMultiselect.vue';
import RocIcon from '@/components/ui/RocIcon.vue';
import RocButton from '@/components/ui/RocButton.vue';
import { MDBDropdown, MDBDropdownItem} from "mdb-vue-ui-kit";
import RocDropdownToggle from '@/components/ui/RocDropdownToggle.vue';
import RocDropdownMenu from './RocDropdownMenu.vue';
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from 'vue';
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'FilterTaggingMultiselect',
  emits: ['tags-filter-change'],
  props: { },
  components: {
    TagGroupMultiselect,
    RocIcon,
    RocButton,
    MDBDropdown,
    MDBDropdownItem,
    RocDropdownToggle,
    RocDropdownMenu
  },
  setup(props, context) {
    const store = useStore();

    onMounted(() => {
      initTagGroups();
    });

    const allAvailableAttributes = computed(function () {
      let faceTags = store.getters['encounters/getAllFaceAttributes'];
      let objTags = store.getters['encounters/getAllObjectAttributes'];

      const groupedOptions = [
        {
          label: 'Face Tags',
          options: faceTags || []
        },
        {
          label: 'Object Tags',
          options: objTags || []
        }
      ];

      return groupedOptions;
    });

    //update store and emit event when tags or andOr selection changes
    //tags param - defined when selection changed in component, null/undefined otherwise
    function updateAttributesFilter(tags=null, clear=false) {
      let filter = [];
      if (tags) {
        //find the tagGroupInstance that matches the index of the selectedTags and update selected tags
        tagGroupInstances.value[tags.index].selectedTags = tags.selectedTags;
      }

      //build an array to set the store values based on tag group instances
      for (let i = 0; i < tagGroupInstances.value.length; i++) {
        if(!clear){
          filter.push({
            selectedTags: tagGroupInstances.value[i].selectedTags,
            andOr: tagGroupInstances.value[i].andOrMenuSelectionText
          });
        } else {
          simpleTagGroupInstanceRef.value?.clearOptions()
          advancedTagGroupInstanceRef.value?.forEach((instance) => {
            instance.clearOptions();
          });
          tagGroupInstances.value[i].selectedTags = [];
          tagGroupInstances.value.splice(1);
          taggingMode.value = "simple";
        }
      }

      store.commit('encounters/setAttributesFilter', filter);
      context.emit("tags-filter-change");
    }

    const taggingMode = ref("simple");
    function toggleTaggingMode() {
      if (taggingMode.value === "simple") {
        taggingMode.value = "advanced";
      }
      else if (tagGroupInstances.value.length === 1) {
          taggingMode.value = "simple";
      }
    };

    const tagsPlaceholderText = ref("Select tags")

    const taggingViewToggleText = computed(() => {
      if (taggingMode.value === "simple") {
        return "Advanced View";
      } else {
        return "Simplified View";
      }
    });

    function andOrSelectionHandler(index, selection) {
      if (selection === 'or') {
        tagGroupInstances.value[index].andOrMenuSelectionText = 'or';
        tagGroupInstances.value[index].andOrMenuSelectionOption = 'and';
      } else {
        tagGroupInstances.value[index].andOrMenuSelectionText = 'and';
        tagGroupInstances.value[index].andOrMenuSelectionOption = 'or';
      }
      tagGroupInstances.value[index].andOrDropdownToggle = false;
    };

    function createNewTagGroup(update=true) {
      tagGroupInstances.value.push({
        id: uniqueId(),
        selectedTags: [],
        andOrDropdownToggle: false,
        andOrMenuSelectionText: 'or',
        andOrMenuSelectionOption: 'and'
      });
      if (update) {
        updateAttributesFilter();
      }
    };

    function removeTagGroup(index) {
      if (tagGroupInstances.value.length > 1) {
        tagGroupInstances.value.splice(index, 1);
      }
      //use trash can to clear all tags if only one tag group exists and trash can is clicked
      else if (tagGroupInstances.value.length === 1) {
        advancedTagGroupInstanceRef.value[index].clearOptions();
      }
      updateAttributesFilter();
    };

    const simpleTagGroupInstanceRef = ref(null);
    const advancedTagGroupInstanceRef = ref(null);

    const tagGroupInstances = ref([]);
    // load initial value based on vuex store
    function initTagGroups() {
      tagGroupInstances.value = [];
      taggingMode.value = 'simple';
      const savedTagGroups = store.getters['encounters/attributesFilter'];
      if (Array.isArray(savedTagGroups) && savedTagGroups.length) {
        // initialize with groups from vuex store
        savedTagGroups.forEach((savedTagGroup) => {
          tagGroupInstances.value.push({
            id: uniqueId(),
            selectedTags: savedTagGroup.selectedTags,
            andOrDropdownToggle: false,
            andOrMenuSelectionText: savedTagGroup.andOr,
            andOrMenuSelectionOption: savedTagGroup.andOr === 'or' ? 'and' : 'or'
          });
        });
        if (tagGroupInstances.value.length > 1) {
          taggingMode.value = 'advanced';
        }
      } else {
        createNewTagGroup(false);
      }
    }

    return {
      updateAttributesFilter,
      allAvailableAttributes,
      taggingMode,
      tagsPlaceholderText,
      toggleTaggingMode,
      taggingViewToggleText,
      andOrSelectionHandler,
      tagGroupInstances,
      createNewTagGroup,
      removeTagGroup,
      simpleTagGroupInstanceRef,
      advancedTagGroupInstanceRef,
      initTagGroups
    }
  }
}

</script>

<style scoped lang="scss">
//this is the input wrapper for the multiselect and any buttons inside the input
.tagging-filter-wrapper {
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  padding: var(--spacing-base) var(--spacing-s) var(--spacing-base) var(--spacing-base);
  display: flex;
  align-items: center;
}

//This is the styling for the dropdown where the options are
.tagging-filter-wrapper :deep(.multiselect-dropdown) {
  width: 240px;
  background-color: var(--overwatch-secondary);
  color: var(--overwatch-neutral-100);
}


//This is the styling for tag itself
.tagging-filter-wrapper :deep(.multiselect-tag) {
  @include overwatch-body-small;
  color: var(--overwatch-button-primary); // Font color
  background-color: var(--overwatch-button-primary-20);
  border: solid 1px var(--overwatch-button-primary); // Tag border
  padding: $--spacing-base $--spacing-base $--spacing-base $--spacing-s; //Tag padding, smaller on right side to make room for X
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

//This is wrapper for all tags (note it's multiselect-tags, not multiselect-tag)
.tagging-filter-wrapper :deep(.multiselect-tags) {
  padding-left: var(--spacing-base);
  background-color: var(--overwatch-neutral-500);
  max-width: 185px; //this keeps the input box from re-sizing when tags are long
}

//This is wrapper for all tags (note it's multiselect-tags, not multiselect-tag)
.tagging-filter-wrapper :deep(.multiselect-tags-search) {
  background-color: var(--overwatch-neutral-500);
  border: none;
}

.tagging-filter-wrapper :deep(.multiselect-tags-search-copy){
  background-color: var(--overwatch-neutral-500);
}

.tagging-filter-wrapper :deep(.multiselect-tags-search-wrapper){
  background-color: var(--overwatch-neutral-500);
}

.tagging-filter-wrapper :deep(.multiselect-clear-icon) {
  display: none;
}

.tagging-filter-wrapper :deep(.multiselect-placeholder) {
  margin-left: -4px;
  background-color: var(--overwatch-neutral-500);
}

.andOr-dropdown-button {
  @include overwatch-title-med;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: $--spacing-base;
}

.andOr-dropdown-button button {
  padding: var(--spacing-s);
  height: 100%;
}

.andOr-dropdown-button button div {
  @include overwatch-body-small;
  display: flex;
  align-items: center;
  gap: $--spacing-s;
}

.tagging-view-toggle-text {
  @include overwatch-body-small;
  text-decoration: underline;
  color: var(--overwatch-button-primary);
  cursor: pointer;
  align-items: start;
}

.tagging-view-toggle-text.disabled {
  color: var(--overwatch-neutral-300);
}

.tagging-button-container {
  display: flex;
  justify-content: end;
  margin-top: $--spacing-s;
  margin-bottom: $--spacing-s;
}
</style>