<template>
  <div>
    <SearchHeader
      class="stick-to-top"
      title='Add Workflow'
      hideSearch
      hideButton
    >
      <template v-slot:leftnav>
        <RocIcon class="search-header-backbutton" icon="downArrow" size="sm" @click="$router.back()"/>
        <span v-if="workflow">
          Edit Workflow<br>
          <span class="overwatch-body-med">Created {{ formattedDatetimeString }}</span>
        </span>
        <span v-else>Add Workflow</span>
      </template>
    </SearchHeader>
    <MDBCard class="workflow-crud card">
      <MDBCardBody>
        <div>
          <div class="top-level-data">
            <div style="display: flex; justify-content: space-between;">
              <div style="flex: 4" class="overwatch-body-large">
                Name
                <RocInput v-model="name" class="overwatch-body-med"/>
              </div>
              <div style="margin-left: 32px; display: flex; flex-direction:column; align-items: center;" class="overwatch-body-large">
                Enabled
                <RocSwitch :isActive="isEnabled" @switch-toggled="isEnabled = $event"/>
              </div>
            </div>
            <div class="overwatch-body-large">
              Description
              <RocTextArea v-model="description" class="overwatch-body-med"/> 
            </div>
          </div>
          <div class="row-div" style="justify-content: space-between; margin-top: 12px;">
            <div class="mdb-input">
              Workflow Type
              <RocSelect
                :availableOptions="workflowTypesOptions"
                :currentlySelected="selectedWorkflowTypeLabel"
                @selection-changed="selectedWorkflowTypeLabel=$event"
                optionLabel="value"
                optionValue="value"
                :key="selectedWorkflowTypeLabel"
                style="min-width: 247px;"
              />
            </div>
          </div>
          <div class="row-div" style="justify-content: space-between; margin-top: 12px;">
            <div class="mdb-input">
              If the following event occurs
              <RocSelect
                :availableOptions="workflowEventsOptions"
                :currentlySelected="selectedWorkflowEventLabel"
                @selection-changed="selectedWorkflowEventLabel=$event"
                optionLabel="value"
                optionValue="value"
                :key="selectedWorkflowEventLabel"
              />
            </div>
          </div>
          <div @click="isConditionsExpanded = !isConditionsExpanded;" style="cursor: pointer; margin-top: var(--spacing-l); margin-bottom: var(--spacing-base);">
            and the following optional conditions associated with the event are met
            <RocIcon
            icon="downArrow"
            :size="'sm'"
            style="transition: transform 0.1s ease; margin-left: var(--spacing-s);"
            :style="{ transform: isConditionsExpanded ? 'rotate(180deg)' : 'rotate(00deg)' }"/>
          </div>
          <MDBCollapse
            class="collapse"
            :class="{'fit-content': conditionsFitContent }"
            v-model="isConditionsExpanded"
          >
            <div>
              <div style="display: flex;">
                <RocButton @click="handleAddConditionGroup" style="margin-left: auto;">Add Condition Group</RocButton>
              </div>
              <div class="row-div overwatch-body-med" v-if="conditionGroups.length === 0">
                No condition groups.
              </div>
              <div class="condition-group" v-for="(group, groupIndex) of conditionGroups" :key="groupIndex">
                <div class="row-div justify-between">
                  <div class="row-div" style="margin-top: 0;">
                    <RocPill :leftText="'Any'" :rightText="'All'" @pill-toggled="group.isAll = !group.isAll "/>
                  </div>
                  <div style="display: flex; align-items: center; gap: 24px;">
                    <RocIcon
                    icon="trash"
                    color="red"
                    size="md"
                    style="cursor: pointer"
                    @click="deleteGroupByIndex(groupIndex)"
                    />
                    <OrderButtons
                      style="height: 100%;"
                      @up="changeOrder(groupIndex, -1, conditionGroups)"
                      @down="changeOrder(groupIndex, 1, conditionGroups)"
                    />
                  </div>
                </div>
                <div v-if="group.conditions.length === 0" class="row-div align-center justify-between overwatch-body-med">
                  No conditions.
                  <RocButton @click="handleAddCondition(group)" style="display:block;">Add Condition Group</RocButton>
                </div>
                <div class="row-div align-center" v-for="(condition, conditionIndex) of group.conditions" :key="condition">
                  <div style="width: 650px;" class="mdb-input overwatch-body-med">
                    Property
                    <MDBSelect
                      filter
                      v-model:options="condition.workflowModelPropertyOptions"
                      v-model:selected="condition.selectedModelPropertyLabel"
                      :preselect="false"
                      :ref="assignRef(condition, 'property')"
                    />
                  </div>
                  <div style="width: 200px;" class="mdb-input overwatch-body-med">
                    Operator
                    <MDBSelect
                      filter
                      v-model:options="condition.operators"
                      v-model:selected="condition.selectedOperator"
                      :preselect="false"
                      :ref="assignRef(condition, 'operator')"
                    />
                  </div>
                  <div style="width: 250px;" class="overwatch-body-med">
                    Value
                    <RocInput class="overwatch-body-large" v-model="condition.value" />
                  </div>
                  <div>
                    <span style="visibility: hidden;">A</span>
                    <RocIcon
                    icon="trash"
                    color="red"
                    size="md"
                    style="cursor: pointer"
                    @click="deleteConditionByIndex(group, conditionIndex)"
                    />
                  </div>
                  <div>
                    <span style="visibility: hidden;">A</span>
                    <OrderButtons
                      style="height: 100%;"
                      @up="changeOrder(conditionIndex, -1, group.conditions)"
                      @down="changeOrder(conditionIndex, 1, group.conditions)"
                    />
                  </div>
                  <div>
                    <span style="visibility: hidden;">A</span>
                    <RocButton style="width: 150px"  @click="handleAddCondition(group)" :class="{hidden: conditionIndex !== group.conditions.length - 1}">Add Condition</RocButton>
                  </div>
                </div>
              </div>
            </div>
          </MDBCollapse>
          <div @click="isActionsExpanded = !isActionsExpanded;" style="cursor: pointer; margin-top: var(--spacing-l); margin-bottom: var(--spacing-base);">
            Then execute the following actions:
            <RocIcon
            icon="downArrow"
            :size="'sm'"
            style="transition: transform 0.1s ease;"
            :style="{ transform: isActionsExpanded ? 'rotate(180deg)' : 'rotate(00deg)' }"/>
          </div>
          <MDBCollapse
            class="collapse"
            :class="{'fit-content': actionsFitContent }"
            v-model="isActionsExpanded"
          >
            <div style="margin-top: 12px;">
              <div v-if="actions.length === 0" class="row-div align-center justify-between">
                No actions.
                <RocButton @click="handleAddAction" style="display:block;">Add action</RocButton>
              </div>
              <div v-for="(action, actionIndex) of actions" :key="actionIndex" style="margin-bottom:14px;">
                <div class="row-div align-center mdb-input" style="margin-bottom: 0px;">
                  <MDBSelect
                    :ref="assignActionSelectRef(action)"
                    filter
                    v-model:options="action.actions"
                    v-model:selected="action.selectedAction"
                    style="width: 300px;"
                    :preselect="false"
                  />
                  <div>
                    <RocButton @click="handleEditActionValue(action)" :disabled="!action.selectedAction">
                      <RocIcon color="white" size="sm" icon="edit" style="margin-right: var(--spacing-base)"/>
                      Edit
                    </RocButton>
                  </div>
                  <RocIcon
                    icon="trash"
                    color="red"
                    size="md"
                    style="cursor: pointer"
                    @click="deleteActionByIndex(actionIndex)"
                    />
                  <OrderButtons style="height: 100%;" @up="changeOrder(actionIndex, -1, actions)" @down="changeOrder(actionIndex, 1, actions)"/>
                  <RocButton v-if="actionIndex === actions.length - 1"
                   @click="handleAddAction">
                    <RocIcon size="sm" icon="add" color="white"/>
                  </RocButton>
                </div>
                <div style="max-height: 150px; overflow: auto; margin-top: 8px;" v-if="action.value">
                  <JsonViewer
                  class="json-editor"
                  :style="{'filter': darkMode ? 'invert(1)' : 'invert(0)'}"
                  v-bind:value="action.value"/>
                </div>
              </div>
            </div>
          </MDBCollapse>
          <div class="row-div" style="flex-direction:row-reverse; margin-bottom: 0; margin-top: 14px;">
            <RocButton @click="saveHandler" :disabled="!isSaveEnabled">Save</RocButton>
            <RocButton @click="closeDialog" type="secondary">Cancel</RocButton>
            <span style="color: var(--overwatch-error); margin-right: 32px;">{{ errorMessage }}</span>
          </div>
        </div>
      </MDBCardBody>
    </MDBCard>
    <BaseDialog
      show
      title="Edit Action Value"
      v-if="isShowingActionValueEditor"
      @close="isShowingActionValueEditor = false"
      :style="dialogStyle"
    >
      <JsonEditor
        class="json-editor"
        v-model="editingActionValueBuffer"
        :style="jsonEditorStyle"
        style="height: 700px; width: 100%; margin: 0; padding: 0;"
      />
      <div class="d-flex justify-content-end" style="margin-top: 10px; gap: var(--spacing-s)">
        <RocButton @click="isShowingActionValueEditor=false;" color="secondary">Cancel</RocButton>
        <RocButton @click="handleSaveActionValue">OK</RocButton>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, reactive, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBSwitch,
  MDBTextarea,
  MDBCollapse,
} from 'mdb-vue-ui-kit';
import SearchHeader from '@/components/ui/SearchHeader';
import BaseDialog from '@/components/ui/BaseDialog'
import OrderButtons from '@/components/ui/OrderButtons.vue';
import JsonEditor from 'json-editor-vue3';
import JsonViewer from 'vue-json-viewer';
import { isEqual } from 'lodash';
import RocSwitch from '@/components/ui/RocSwitch.vue';
import RocButton from '@/components/ui/RocButton.vue';
import RocIcon from '@/components/ui/RocIcon.vue';
import RocPill from '@/components/ui/RocPill.vue';
import RocInput from '@/components/ui/RocInput.vue';
import RocTextArea from '@/components/ui/RocTextArea.vue';
import RocSelect from '@/components/ui/RocSelect';

export default {
  name: 'WorkflowCRUD',
  components: {
    MDBCard,
    MDBCardBody,
    MDBSelect,
    MDBSwitch,
    MDBTextarea,
    MDBCollapse,
    SearchHeader,
    JsonEditor,
    BaseDialog,
    JsonViewer,
    OrderButtons,
    RocSwitch,
    RocButton,
    RocIcon,
    RocPill,
    RocInput,
    RocTextArea,
    RocSelect
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    const windowWidth = ref(window.innerWidth);

    const workflow = computed(() => {
      return store.getters['workflows/editingWorkflow']
    })

    const isConditionsExpanded = ref(true);
    const isActionsExpanded = ref(true);

    /** Setup */
    const workflowTypes = ref([]);
    async function loadWorkflowTypes() {
      const response = await store.dispatch('workflows/getWorkflowTypes');

      workflowTypes.value = response.result;
    }
    const workflowTypesOptions = computed(() => {
      var output = listToTextValueObject(workflowTypes.value.map(s => s.name));
      output.sort(optionSort);
      return output;
    });
    const selectedWorkflowTypeLabel = ref();
    const selectedWorkflowType = computed(() => {
      return workflowTypes.value.find(s => s.name === selectedWorkflowTypeLabel.value);
    });

    const workflowEventsOptions = computed(() => {
      if (selectedWorkflowType.value) {
        const workflowEvents = selectedWorkflowType.value.events;
        var output = listToTextValueObject(workflowEvents.map(e => e.name));
        output.sort(optionSort);
        return output;
      }
      return [];
    });
    const selectedWorkflowEventLabel = ref();
    const selectedWorkflowEvent = computed(() => {
      if(selectedWorkflowType.value && selectedWorkflowEventLabel.value) {
        return selectedWorkflowType.value.events.find(e=> e.name === selectedWorkflowEventLabel.value)
      }
    })

    const conditionGroups = ref([]);
    const name = ref('');
    const description = ref('');
    const isEnabled = ref(true);
    const actions = ref([]);

    // Edit Mode
    const propEvent = ref();
    const propConditionGroups = ref();
    const propActions = ref();

    if (workflow.value) {

      // 1. Identify the condition groups / conditions / actions from a prop.workflow
      const workflowConditionsObject = workflow.value.rule.conditions;

      propEvent.value = workflowConditionsObject.all[0]
      propConditionGroups.value = workflowConditionsObject.all[1]?.any;
      propActions.value = workflow.value.rule.event.params;

      // 2. Create front end condition groups and condition objects
      if (propConditionGroups.value) {
        for (let [i, g] of propConditionGroups.value.entries()) {
          const group = createConditionGroup();
          conditionGroups.value.push(group);

          const propConditions = conditionsOfGroup(g);

          if (getGroupAnyAll(g) === 'any') {
            group.isAll.value = false;
          } else {
            group.isAll.value = true;
          }

          for (let [j, c] of propConditions.entries()) {
            var condition = createCondition();
            group.conditions.value.push(condition);
          }

        }
      }

      for (let a of propActions.value) {
        var action = createAction();
        actions.value.push(action);
      }


      // 3. Associate the MDBSelects and inputs with refs in the objects from step 2

      // Step 3 happens in <template>
      // Step 4 happens in onMounted.

    }

    function assignRef(condition, property) {
      return (ref) => {
        switch (property) {
          case 'property':
            condition.propertyRef = ref;
            break;
          case 'operator':
            condition.operatorRef = ref;
            break;
        }
      }
    }

    const isPopulatingEdits = ref(false);
    onMounted(async () => {
      onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

      await loadWorkflowTypes();

      // 4. Set values
      // All the nextTick() calls ensure that the next MDBSelect is populated
      if (workflow.value) {
        isPopulatingEdits.value = true;

        name.value = workflow.value.name;
        description.value = workflow.value.description;
        isEnabled.value = workflow.value.enabled;

        await nextTick();
        selectedWorkflowTypeLabel.value = workflow.value?.type.name

        await nextTick();
        selectedWorkflowEventLabel.value = propEvent.value.value;

        for (let [i, group] of conditionGroups.value.entries()) {
          const propConditions = conditionsOfGroup(propConditionGroups.value[i]);
          for (let [j, condition] of group.conditions.entries()) {
            const propCondition = propConditions[j];

            await nextTick();
            condition.propertyRef.setValue(
              propCondition?.fact + propCondition?.path.substring(1)
            );

            await nextTick();
            condition.operatorRef.setValue(propCondition?.operator);

            condition.value = propCondition?.value?.toString();
          }
        }

        for (let [i, action] of actions.value.entries()) {
          action.selectRef.setValue(propActions?.value[i]?.name);
          action.value = propActions?.value[i]?.config;
        }
      }

      await nextTick();
      const onStartName = name.value;
      const onStartDescription = description.value;
      const onStartIsEnabled = isEnabled.value;
      const onStartConditionGroups = conditionGroupsHelper(conditionGroups.value);
      const onStartActions = actionsHelper(actions.value);

      watch(name, nv => {
        checkValueChange(onStartName, onStartDescription, onStartIsEnabled, onStartConditionGroups, onStartActions)
      })
      watch(description, nv => {
        checkValueChange(onStartName, onStartDescription, onStartIsEnabled, onStartConditionGroups, onStartActions)
      })
      watch(isEnabled, nv => {
        checkValueChange(onStartName, onStartDescription, onStartIsEnabled, onStartConditionGroups, onStartActions)
      })
      watch(conditionGroups, nv => {
        checkValueChange(onStartName, onStartDescription, onStartIsEnabled, onStartConditionGroups, onStartActions)
      }, {deep:true})
      watch(actions, nv => {
        checkValueChange(onStartName, onStartDescription, onStartIsEnabled, onStartConditionGroups, onStartActions)
      }, {deep:true})
    });


    /** Conditions Logic */

    function createConditionGroup() {
      var newConditionGroup = {};

      newConditionGroup.isAll = ref(false);
      newConditionGroup.anyAll = computed(() => {
        if (newConditionGroup.isAll.value) {
          return 'all';
        } else {
          return 'any';
        }
      })

      newConditionGroup.conditions = ref([]);
      // Have at least one default condition if in add mode, or if there arent any in the group.
      if (!workflow.value || ( workflow.value && workflow.value?.rule?.conditions?.all?.length === 1)) {
        newConditionGroup.conditions.value.push(createCondition());
      } else console.log(workflow.value?.rule?.conditions?.all?.length === 1)
      return newConditionGroup;
    }

    function createCondition() {
      var con = {};

      con.workflowModelPropertyOptions = computed(() => {
        if (selectedWorkflowEvent.value) {
          const workflowModelProperties = selectedWorkflowEvent.value.properties;

          var output = listToTextValueObject(
            workflowModelProperties.map(p => p.name)
          );
          output.sort(optionSort);
          return output;
        }
        return [];
      });
      con.selectedModelPropertyLabel = ref();
      con.selectedModelProperty = computed(() => {
        if (selectedWorkflowEvent.value && con.selectedModelPropertyLabel.value) {
          return selectedWorkflowEvent.value.properties.find(
            p => p.name === con.selectedModelPropertyLabel.value
          );
        }
        return [];
      });
      con.selectedModelPropertyType = computed(() => {
        return con.selectedModelProperty.value.type;
      });

      con.operators = computed(() => {
        if (selectedWorkflowType.value && con.selectedModelPropertyType.value) {
          const typeOperations = selectedWorkflowType.value.typeOperations;

          const typeOperation = typeOperations.find(o => o.type === con.selectedModelPropertyType.value);
          if (typeOperation) {
            var output = listToTextValueObject(
              typeOperations.find(o => o.type === con.selectedModelPropertyType.value).operators
            );
            output.sort(optionSort);
            return output;
          }
        }
        return [];
      });
      con.selectedOperator = ref();

      con.value = ref();

      /** Refs for MDBSelect */
      con.propertyRef = ref();
      con.operatorRef = ref();

      return con;
    }


    /** Actions logic */

    if (!workflow.value) {
      actions.value.push(createAction());
    }

    function createAction() {
      const newAction = reactive({
        actions: listToTextValueObject(['sendEmailSMTP', 'postUrl', 'putUrl', 'pushNotification', 'enqueueRedisMessage', 'enqueueSQSMessage','enqueueMQTTMessage','setObject','uploadObjectPropertiesToS3','getUrlAndSetProperty','unsetObjectProperties','setObjectProperties','imageBuilder','sendTwilioMMS']),
        selectedAction: null,
        value: '',
        selectRef: null,
        firstSelect: workflow.value ? true : false
      });

      watch(() => newAction.selectedAction, async (nv) => {
        if (nv) {
          // Prevent default action value on Edit as to not override existing data.
          if (newAction.firstSelect) {
            newAction.firstSelect = false;
          }
          else {
            newAction.value = await getDefaultActionValue(nv);
          }
        }
      });

      return newAction;
    }


    async function getDefaultActionValue(action) {
      const response = await store.dispatch('workflows/getDefaultActionValue', action);
      if (response.status === 'success') {
        return response.value;
      }
      return '';
    }

    const isShowingActionValueEditor = ref(false);

    const editingActionValueBuffer = ref();
    const currentEditingAction = ref();
    function handleEditActionValue(action) {
      currentEditingAction.value = action;

      editingActionValueBuffer.value = action.value;

      isShowingActionValueEditor.value = true
    }

    function handleSaveActionValue() {
      currentEditingAction.value.value = editingActionValueBuffer.value;
      isShowingActionValueEditor.value = false;
    }

    function assignActionSelectRef(action) {
      return (ref) => {
        action.selectRef = ref;
      }
    }

    function validateActions() {
      const output = {
        errors: []
      }
      for (let action of actions.value) {
        if (!validateActionInput(action.selectedAction, action.value)) {
          output.errors.push(`${action.selectedAction} ${action.value}`)
        }
      }
      if (output.errors.length > 0) {
        output.valid = false
      } else {
        output.valid = true;
      }
      return output;
    }

    function validateActionInput(action, input) {
      switch(action) {
        case 'SMS':
          return true;
        case 'sendEmailSMTP':
          // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
          return input
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        case 'postUrl':
          return input.includes('http://') || input.includes('https://');
        default:
          return false;
      }
    }



    /** */

    function createWorkflowRule() {
      // 'Translate' conditionGroups to the json rule engine format
      const groups = [];

      for (let g of conditionGroups.value) {
        const group = {};

        var groupListPtr;
        if (g.anyAll === 'any') {
          group.any = [];
          groupListPtr = group.any;
        } else {
          group.all = [];
          groupListPtr = group.all;
        }

        for (let c of g.conditions) {
          const condition = {};

          var parts = c.selectedModelPropertyLabel.split('.');
          var fact = parts[0];
          var path = parts.slice(1).join('.');

          condition.fact = fact;
          condition.path = `$.${path}`;
          condition.operator = c.selectedOperator;

          if(c.selectedModelPropertyType === 'boolean') {
            if(c.value === 'true' || c.value === true) {
              condition.value = true;
            } else {
              condition.value = false;
            }
          } else if (c?.selectedModelPropertyType === 'number') {
            condition.value = Number(c.value);
          } else if (c?.selectedModelPropertyType === 'date') {
            condition.value = new Date(c.value);
          } else if (c.selectedModelPropertyType === 'array') {
            condition.value = c.value?.split(',');
          } else {
            condition.value = c.value?.toString();
          }

          groupListPtr.push(condition);
        }

        groups.push(group);
      }

      const acts = [];
      for (let a of actions.value) {

        const action = {};

        action.name = a.selectedAction;
        action.type = a.selectedAction;
        action.config = a.value;

        acts.push(action);
      }

      const rule = {
        conditions: {
          all: [
            {
              fact: 'reserved_rf_event_type',
              operator: 'equal',
              value: selectedWorkflowEventLabel.value
            },
            ... groups.length > 0 ? [{ any: groups }] : []    // If any groups, then add {any:groups} to the top level 'all'.
          ]
        },
        event: {
          type: 'action_array',
          params: acts
        }
      };
      return rule;
    }

    const errorMessage = ref('');

    function setErrorMessage(message) {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = '';
      }, 5000)
    }

    async function saveHandler() {
      if (workflow.value) {
        await editWorkflow();
      } else {
        await createWorkflow();
      }

      // const validation = validateActions();

      // if (validation.valid) {
      //   if (workflow.value) {
      //     await editWorkflow();
      //   } else {
      //     await createWorkflow();
      //   }
      // } else {
      //   var errorString = '';
      //   for (let e of validation.errors) {
      //     if (errorString === '') {
      //       errorString += e
      //     } else {
      //       errorString = errorString + ', ' + e
      //     }
      //   }
      //   setErrorMessage('Please correct the following actions: '  + errorString);
      // }
    }

    async function createWorkflow() {
      const payload = {
        name: name.value,
        description: description.value,
        enabled: isEnabled.value,
        rule: createWorkflowRule(),
        type: selectedWorkflowType.value
      };

      const response = await store.dispatch('workflows/createWorkflow', payload);

      if (response.status === 'success') {
        router.push('/workflows');
      } else {
        setErrorMessage('Failed saving workflow.');
      }

    }

    async function editWorkflow() {
      const payload = {
        id: workflow.value._id,
        name: name.value,
        description: description.value,
        enabled: isEnabled.value,
        rule: createWorkflowRule(),
        type: selectedWorkflowType.value
      };

      const response = await store.dispatch('workflows/updateWorkflow', payload);

      if (response.status === 'success') {
        router.push('/workflows');
      } else {
        setErrorMessage('Failed saving workflow.');
      }

    }

    function closeDialog() {
      router.push('/workflows');
    }


    /** Misc. Helpers */

    const conditionsFitContent = ref(isConditionsExpanded.value);   // For css trickery with MDBCollapse.
    watch(isConditionsExpanded, nv => {
      // Fit content is necessary otherwise the collapsible won't change size when new conditions are added.
      // If we have fitContent on the collapsible 100% of the time, animation is jittery. Thus we only want to fitContent
      // once the animation has finished... 350ms is arbitrary but it looks like a good compromise
      if (nv) {
        setTimeout(() => {
          conditionsFitContent.value = true;
        }, 350);

      } else {
        conditionsFitContent.value = false;
      }
    })

    const actionsFitContent = ref(isActionsExpanded.value);
    watch(isActionsExpanded, nv => {
      if (nv) {
        setTimeout(() => {
          actionsFitContent.value = true;
        }, 350);
      } else {
        actionsFitContent.value = false;
      }
    })


    function handleAddConditionGroup() {
      conditionGroups.value.push(createConditionGroup());
    }

    function handleAddCondition(conditionGroup) {
      conditionGroup.conditions.push(createCondition());
    }

    function handleAddAction() {
      actions.value.push(createAction());
    }

    function deleteGroupByIndex(groupIndex) {
      conditionGroups.value.splice(groupIndex, 1);
    }

    function deleteConditionByIndex(group, conditionIndex) {
      group.conditions.splice(conditionIndex, 1);
    }

    function deleteActionByIndex(idx) {
      actions.value.splice(idx, 1);
    }

    function listToTextValueObject(list) {
      const options = [];
      list.forEach(s => {
        options.push({
          text: s, value: s
        });
      });
      return options;
    }

    /**
     * Conditions from a prop.workflow condition gruop
     */
    function conditionsOfGroup(conditionGroup) {
      if (Object.keys(conditionGroup).includes('any')) {
        return conditionGroup.any;
      } else {
        return conditionGroup.all;
      }
    }

    function getGroupAnyAll(group) {
      if (Object.keys(group).includes('any')) {
        return 'any';
      } else {
        return 'all'
      }
    }

    const formattedDatetimeString = computed(() => {
      if (workflow.value) {
        const datetime = new Date(Date.parse(workflow.value.createdUTC));
        return datetime.toLocaleString();
      }
      return '';
    });

    const isSaveEnabled = ref(false);

    function checkValueChange(onStartName, onStartDescription, onStartIsEnabled, onStartConditionGroups, onStartActions) {
      if (
        isEqual(name.value, onStartName) &&
        isEqual(description.value, onStartDescription) &&
        isEqual(isEnabled.value, onStartIsEnabled) &&
        isEqual(conditionGroupsHelper(conditionGroups.value), onStartConditionGroups) &&
        isEqual(actionsHelper(actions.value), onStartActions)
      ) {
        isSaveEnabled.value = false;
      } else {
        isSaveEnabled.value = true;
      }
    }

    // Helpers to convert groups and actions to something easily comparable.
    function conditionGroupsHelper(cg) {
      const output = []
      for (let group of cg) {
        const g = {}
        g.anyAll = group.anyAll;
        g.conditions = [];
        for (let condition of group.conditions) {
          const c = {};
          c.property = condition.selectedModelPropertyLabel;
          c.operator = condition.selectedOperator;
          c.value = condition.value;

          g.conditions.push(c);
        }
        output.push(g)
      }
      return output;
    }
    function actionsHelper(actions) {
      const output = [];
      for (let action of actions) {
        const a = {};
        a.name = action.name;
        a.value = action.value;

        output.push(a);
      }
      return output;
    }

    function optionSort(a,b) {
      if (a.value < b.value) {
        return -1;
      } return 1;
    }

    function changeOrder(index, direction, list) {
      if (direction > 0 && index < list.length-1) {
        let buff = list[index];
        list[index] = list[index + 1];
        list[index + 1] = buff;
      }
      if (direction < 0 && index > 0) {
        let buff = list[index];
        list[index] = list[index - 1];
        list[index - 1] = buff;
      }
    }

    const darkMode = computed(() => store.getters['settings/getDarkMode']);
    const dialogStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          'width': '100%',
          'height': '1000px'
        }
      }
      else{
        return {
          'width': '900px',
          'max-width': '90%',
          'max-height': '90%'
        }
      }
    });

    const jsonEditorStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          'height': '80vh',
          'filter': darkMode.value ? 'invert(1)' : 'invert(0)'
        }
      }
      else{
        return {
          'height': '500px',
          'max-height': '90%',
          'filter': darkMode.value ? 'invert(1)' : 'invert(0)'
        }
      }
    });

    return {
      workflow,
      name,
      description,
      conditionGroups,
      createConditionGroup,
      createCondition,
      handleAddConditionGroup,
      handleAddCondition,
      deleteGroupByIndex,
      deleteConditionByIndex,
      actions,
      createAction,
      handleAddAction,
      deleteActionByIndex,
      saveHandler,
      closeDialog,
      assignRef,
      assignActionSelectRef,
      workflowTypesOptions,
      selectedWorkflowTypeLabel,
      workflowEventsOptions,
      selectedWorkflowEventLabel,
      isEnabled,
      isConditionsExpanded,
      isActionsExpanded,
      conditionsFitContent,
      actionsFitContent,
      formattedDatetimeString,
      isSaveEnabled,
      errorMessage,
      isShowingActionValueEditor,
      currentEditingAction,
      editingActionValueBuffer,
      handleEditActionValue,
      handleSaveActionValue,
      changeOrder,
      darkMode,
      dialogStyle,
      jsonEditorStyle
    };
  }
};
</script>

<style scoped lang="scss">
.workflow-crud {
  @include overwatch-body-large;
  flex: unset !important;
}

.card-body{
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
}

.workflow-crud .form-outline input, .workflow-crud textarea, .workflow-crud .select-options-wrapper{
  background-color: var(--overwatch-secondary) !important;
}

/* Trick to make the collapsible expand with new condition groups */
.workflow-crud .fit-content {
  height: fit-content !important;
}

/* For json viewer */
.jv-code {
  padding: 0 !important;
  margin-top: 4px;
}

.mdb-input :deep(.select-input){
  padding: 12px !important;
  @include overwatch-body-med;
  background-color: var(--overwatch-neutral-500) !important;

}

.mdb-input :deep(.select-option){
  @include overwatch-body-med;
}

.json-editor :deep(.json-editor-vue),
.json-editor :deep(.json-editor-vue) * {
  font-family: consolas, menlo, monaco, "Ubuntu Mono", source-code-pro, monospace;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}
.card {
  margin: 12px;
}

.top-level-data div{
  margin-bottom: 12px;
}
.row-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 12px;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.condition-group {
  border: 1px solid var(--overwatch-accent);
  border-radius: 5px;
  background: var(--overwatch-button-primary-20);
  margin-top: 12px;
  padding: 12px;
}

.condition-group:first-of-type {
  margin-top: 0;
}

.hidden {
  visibility: hidden;
}

.search-header-backbutton {
  cursor: pointer;
  margin-right: 24px;
  transform: rotate(90deg);
}

.collapse {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200)
}

.collapse-icon {
  margin-left: 4px;
}

</style>
